import { RefObject } from 'react';
import InitaialLeftImage from 'assets/images/va_upper_one.svg';
import NoteIcon from 'assets/images/va_upper_note.svg';
import { VaEmailInput, VaInprogress } from './VaCommonComponent';
import { StyledText, Button } from './VaBanner.styles';
import './VaBanner.css';
import { useTranslation } from 'react-i18next';

interface VaBannerProps {
  error: boolean;
  reportSubmited: boolean;
  handleVaReportSubmit: (e: any) => void;
  vaEmailInputRef: RefObject<any>;
  isDDG?: boolean;
  isMobile?: boolean;
}

const VaHeader = () => {
  const [t] = useTranslation();
  return (
    <>
      <div className="va-upper-right-section-title">
        {t('Get your free Field Variability Assessment and Auto zone Report')}
      </div>
      <div className="va-upper-right-section-text-one">
        {t(
          'Discover the best planting strategy fro maximum yield with our comprehensive Field Assessment'
        )}
      </div>
      <div className="va-upper-right-section-text-two">
        <div className="note-icon">
          <img src={NoteIcon} alt="va" />
        </div>
        <div className="note-text">
          {t(
            'Please ensure that your field boundaries are accurate for the most precise assessment'
          )}
        </div>
      </div>
    </>
  );
};

const VaInitialBannerLeftImg = () => {
  return (
    <div className="va-banner-left-image">
      <img src={InitaialLeftImage} alt="va-left" />
    </div>
  );
};
export const VaUpperIntialBanner = (props: VaBannerProps): JSX.Element => {
  const { error, reportSubmited, handleVaReportSubmit, vaEmailInputRef, isDDG, isMobile } = props;

  const [t] = useTranslation();
  return (
    <>
      {!reportSubmited ? (
        <div className="va-upper-banner-main-container">
          <VaInitialBannerLeftImg />
          <div className="va-upper-right-section">
            <VaHeader />
            <div className="input-group">
              {!isDDG && (
                <VaEmailInput
                  error={error}
                  vaEmailInputRef={vaEmailInputRef}
                  upperBanner={true}
                  isDDG={isDDG}
                  isMobile={isMobile}
                />
              )}
              {!isMobile && (
                <Button onClick={handleVaReportSubmit} isDDG={isDDG}>
                  {t('Get free Assessment')}
                </Button>
              )}
            </div>
            {!isDDG && (
              <StyledText
                error={error}
                top={false}
                upperBanner={true}
                isDDG={isDDG}
                isMobile={isMobile}
              >
                {t(
                  'Assessment take 2-3 hours to prepare. We will notify you via email when it is ready.'
                )}
              </StyledText>
            )}
            {isMobile && (
              <Button onClick={handleVaReportSubmit} isDDG={isDDG} isMobile={isMobile}>
                {t('Get free Assessment')}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <VaInprogress upperBanner={true} isMobile={isMobile} />
      )}
    </>
  );
};
