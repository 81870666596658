import { RefObject, useState } from 'react';
import LeftImage from 'assets/images/left_va_web.png';
import MiddleImage from 'assets/images/middle_va_web.png';
import RightImage from 'assets/images/right_va_web.png';
import './VaBanner.css';
import { StyledText, Button } from './VaBanner.styles';
import { VaEmailInput, VaBannerHeader, VaInprogress } from './VaCommonComponent';
import { useTranslation } from 'react-i18next';

export interface VaBannerProps {
  error: boolean;
  reportSubmited: boolean;
  handleVaReportSubmit: (e: any) => void;
  vaEmailInputRef: RefObject<any>;
  isDDG?: boolean;
}

const CardSlider = () => {
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  const cards = [
    { id: 1, image: LeftImage, width: 450, height: 238 },
    { id: 2, image: MiddleImage, width: 196, height: 260 },
    { id: 3, image: RightImage, width: 450, height: 235 },
  ];

  const getCardPosition = (index: number) => {
    if (index === 0) {
      return 'left';
    }
    if (index === 2) {
      return 'right';
    }
    return 'center';
  };

  return (
    <div className="card-slider">
      {cards.map((card, index) => {
        const cardPosition = getCardPosition(index);
        const isHovered = hoveredCard !== null;

        return (
          <div
            key={card.id}
            className={`card ${isHovered ? 'hovered-state' : ''} ${cardPosition}`}
            onMouseEnter={() => setHoveredCard(card.id)}
            onMouseLeave={() => setHoveredCard(null)}
            role="img"
            aria-label={`Card ${card.id}`}
          >
            <img
              src={card.image}
              alt={`Card ${card.id}`}
              style={{
                width: `${card.width}px`,
                height: `${card.height}px`,
                objectFit: 'cover'
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const VaLowerBanner = (props: VaBannerProps): JSX.Element => {
  const { error, reportSubmited, handleVaReportSubmit, vaEmailInputRef, isDDG } = props;
  const [t] = useTranslation();
  return (
    <div className="va-lower-banner">
      <div className="va-main-container">
        <div className="va-upper-banner">
          <VaBannerHeader reportSubmited={reportSubmited} />
          {!reportSubmited ? (
            <>
              <div className="input-group">
                {!isDDG && (
                  <VaEmailInput
                    error={error}
                    vaEmailInputRef={vaEmailInputRef}
                    upperBanner={false}
                    isDDG={isDDG}
                  />
                )}
                <Button onClick={handleVaReportSubmit} isDDG={isDDG}>
                  {t('Get free Assessment')}
                </Button>
              </div>
              {isDDG && (
                <StyledText error={error} top={false} upperBanner={false} isDDG={isDDG}>
                  {t('Assessment will be available within 24 hours.')}
                </StyledText>
              )}
            </>
          ) : (
            <VaInprogress upperBanner={false} />
          )}
        </div>
        <CardSlider />
      </div>
    </div>
  );
};

export default VaLowerBanner;
