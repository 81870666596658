import { CSSProperties } from 'react';
import ButtonRectangle from 'components/Buttons/ButtonRectangle/ButtonRectangle';
import { ButtonProps } from 'antd';
import { RoundedDirections } from 'base/enums/components/buttons';
import { StyledIconPlus, StyledIconMinus, StyledContainer } from './ZoomControl.styles';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useTranslation } from 'react-i18next';

interface IProps {
  id?: string;
  buttonPlusProps?: ButtonProps;
  buttonMinusProps?: ButtonProps;
  style?: CSSProperties;
  showTooltip?: boolean;
}

const ZoomControl = ({
  id,
  buttonPlusProps,
  buttonMinusProps,
  style,
  showTooltip = true,
}: IProps) => {
  const [t] = useTranslation();

  return (
    <StyledContainer id={id} data-testid={id} style={style}>
      <ButtonRectangle
        roundedDirection={RoundedDirections.top}
        icon={<StyledIconPlus />}
        buttonProps={buttonPlusProps}
        style={{ backgroundColor: colors.neutral90 }}
        {...(showTooltip && {
          tooltipProps: {
            title: t('Zoom in'),
            placement: 'left',
          },
        })}
      />
      <ButtonRectangle
        roundedDirection={RoundedDirections.bottom}
        icon={<StyledIconMinus />}
        buttonProps={buttonMinusProps}
        style={{ backgroundColor: colors.neutral90 }}
        {...(showTooltip && {
          tooltipProps: {
            title: t('Zoom out'),
            placement: 'left',
          },
        })}
      />
    </StyledContainer>
  );
};

export default ZoomControl;
