/* eslint-disable array-bracket-newline */
import { useCallback, useMemo, useState, useEffect } from 'react';

import { FlowSteps, ModeTypes } from 'context/store/flowReducer';
import { ICrop } from 'base/types/Crop';
import { ProductSeed } from 'base/types/Products';
import { trackStepTwoEditingStarted } from 'utils/helpers/amplitude';
import { useBreakpoint } from 'hooks';
import { t } from 'i18next';
import { Field } from 'context/store/recommendationFormReducer';
import { DDLOptions } from 'context/store/recommendationFormReducer';
import { CropConstants } from 'utils/constants/Crop';

interface IProps {
  currentStep: FlowSteps;
  currentModeType: ModeTypes;
  plantingDate: moment.Moment | null;
  yieldRangeId: string;
  agProducts: ProductSeed[];
  selectedCrop?: ICrop;
  rotationIntensity: string;
  tillagePractice: string;
  selectedHerbicideTrait: DDLOptions[] | [];
  selectedSegment: string[] | [];
  isBroomrapeInfestation: boolean;
  selectedBroomrape: string[];
  fields?: Field[];
  isContinueButtonActive?: boolean;
  isAnotherRecommendationCreated: boolean;
  countryCode?: string;
}

const useValidationSteps = ({
  currentStep,
  currentModeType,
  agProducts,
  plantingDate,
  selectedCrop,
  yieldRangeId,
  rotationIntensity,
  tillagePractice,
  selectedHerbicideTrait,
  selectedSegment,
  isBroomrapeInfestation,
  selectedBroomrape,
  fields,
  isContinueButtonActive,
  isAnotherRecommendationCreated,
  countryCode,
}: IProps) => {
  const { isMobile } = useBreakpoint();
  const CANCEL_TEXT = 'Cancel';
  const SAVE_TEXT = 'Save';
  const NEXT_TEXT = 'Next';
  const BACK_TEXT = 'Back';
  const FINISH_TEXT = 'Finish';
  const CONTINUE_TEXT = 'Continue';

  const [disabledPrevBtn, setDisabledPrevBtn] = useState(false);
  const [disabledNextBtn, setDisabledNextBtn] = useState(false);
  const [nextButtonText, setNextButtonText] = useState(isMobile ? SAVE_TEXT : NEXT_TEXT);
  const [backButtonText, setBackButtonText] = useState(isMobile ? CANCEL_TEXT : BACK_TEXT);
  const [isCancelButton, setIsCancelButton] = useState(false);

  const validateFirstStep = useCallback(() => {
    setDisabledPrevBtn(true);
    const nextBtnTextForMobile = isContinueButtonActive ? CONTINUE_TEXT : SAVE_TEXT;
    setNextButtonText(isMobile ? nextBtnTextForMobile : NEXT_TEXT);
    setBackButtonText(
      isMobile || (fields?.length && !isMobile && currentModeType === ModeTypes.CREATING)
        ? CANCEL_TEXT
        : BACK_TEXT
    );
    if (currentModeType !== ModeTypes.NO_ACTION && currentModeType !== ModeTypes.SAVING) {
      setDisabledNextBtn(true);
    } else {
      setDisabledNextBtn(false);
    }
  }, [currentModeType, fields, isMobile, isContinueButtonActive]);

  const validateSecondStep = useCallback(() => {
    if (selectedCrop?.type) {
      const cropType = selectedCrop?.type.toLowerCase();
      switch (true) {
        case cropType?.includes('hybrid barley'):
        case cropType?.includes('grain corn'):
        case cropType?.includes('summer corn'):
        case cropType?.includes('silage corn'):
        case cropType?.includes('wheat'):
          setDisabledNextBtn(
            Boolean(!plantingDate || !yieldRangeId || agProducts.length < 3 || !selectedCrop?.name)
          );
          break;
        case cropType?.includes('sunflower'):
          if (isBroomrapeInfestation && countryCode !== CropConstants.ROMANIA_POSTAL_CODE) {
            setDisabledNextBtn(
              Boolean(
                !rotationIntensity ||
                  !tillagePractice ||
                  !(selectedBroomrape.length > 0) ||
                  !(selectedSegment.length > 0) ||
                  !plantingDate ||
                  !yieldRangeId ||
                  agProducts.length < 3 ||
                  !selectedCrop?.name
              )
            );
          } else {
            setDisabledNextBtn(
              Boolean(
                !rotationIntensity ||
                  !tillagePractice ||
                  !(selectedSegment.length > 0) ||
                  !plantingDate ||
                  !yieldRangeId ||
                  agProducts.length < 3 ||
                  !selectedCrop?.name
              )
            );
          }
          break;
        default:
          setDisabledNextBtn(true);
      }
    } else {
      setDisabledNextBtn(true);
    }
    setBackButtonText(t(BACK_TEXT));
    setDisabledPrevBtn(isAnotherRecommendationCreated);
    setNextButtonText(t(FINISH_TEXT));
    trackStepTwoEditingStarted();
  }, [
    selectedCrop,
    isAnotherRecommendationCreated,
    plantingDate,
    yieldRangeId,
    agProducts.length,
    isBroomrapeInfestation,
    rotationIntensity,
    tillagePractice,
    selectedHerbicideTrait.length,
    selectedBroomrape.length,
    selectedSegment.length,
  ]);

  const previousStepMap = useMemo(
    (): { [key in FlowSteps]: FlowSteps | undefined } => ({
      [FlowSteps.STEP1]: undefined,
      [FlowSteps.STEP2]: FlowSteps.STEP1,
      [FlowSteps.STEP3]: FlowSteps.STEP2,
      [FlowSteps.STEP4]: undefined,
      [FlowSteps.empty]: undefined,
    }),
    []
  );

  const nextStepMap = useMemo(
    (): { [key in FlowSteps]: FlowSteps | undefined } => ({
      [FlowSteps.STEP1]: FlowSteps.STEP2,
      [FlowSteps.STEP2]: FlowSteps.STEP3,
      [FlowSteps.STEP3]: undefined,
      [FlowSteps.STEP4]: undefined,
      [FlowSteps.empty]: undefined,
    }),
    []
  );

  useEffect(() => {
    if (backButtonText === CANCEL_TEXT && !isMobile) {
      setIsCancelButton(true);
    } else {
      setIsCancelButton(false);
    }
  }, [backButtonText, isMobile]);

  useEffect(() => {
    if (
      fields?.length &&
      currentModeType === ModeTypes.CREATING &&
      currentStep === FlowSteps.STEP1
    ) {
      setBackButtonText(CANCEL_TEXT);
      setDisabledPrevBtn(false);
    }
  }, [fields, currentModeType, currentStep]);

  const validationStepMap = useMemo(
    (): { [key in FlowSteps]: () => void | undefined } => ({
      [FlowSteps.STEP1]: validateFirstStep,
      [FlowSteps.STEP2]: validateSecondStep,
      [FlowSteps.STEP3]: () => undefined,
      [FlowSteps.STEP4]: () => undefined,
      [FlowSteps.empty]: () => undefined,
    }),
    [validateFirstStep, validateSecondStep]
  );

  useEffect(() => {
    validationStepMap[currentStep]();
  }, [currentStep, validationStepMap]);

  return {
    nextStepMap,
    previousStepMap,
    disabledPrevBtn,
    disabledNextBtn,
    nextButtonText,
    backButtonText,
    isCancelButton,
  };
};

export default useValidationSteps;
