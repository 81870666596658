import React, { useEffect, useRef } from 'react';
import LeftImage from 'assets/images/letf_va_mobile.png';
import MiddleImage from 'assets/images/middle_va_mobile.png';
import RightImage from 'assets/images/right_va_mobileOne.svg';
import './VaBanner.css';
import {
  CarouselBlurOverlay,
  Button,
  StyledText,
  VaLowerBannerMainContainer,
  VaLowerBannerSubContainer,
  VaSubmitMainContainerMobile,
  VaLowerBannerSecondContainer,
} from './VaBanner.styles';
import { VaEmailInput, VaBannerHeader, VaInprogress } from './VaCommonComponent';
import { useTranslation } from 'react-i18next';
import { VaBannerProps } from './VaLowerBanner';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface VaMobileProps {
  error: boolean;
  vaEmailInputRef: React.RefObject<any>;
  isDDG: boolean | undefined;
  handleVaReportSubmit: (e: any) => void;
}
const CardSlider = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (containerRef.current) {
        const prevButton = containerRef.current.querySelector('.control-prev');
        if (prevButton) {
          (prevButton as HTMLElement).click();
        }
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const images = [
    { id: 1, image: LeftImage },
    { id: 2, image: MiddleImage },
    { id: 3, image: RightImage },
  ];
  return (
    <div className="card-slider-container" ref={containerRef}>
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={false}
        centerMode={true}
        showIndicators={false}
        swipeable={false}
        emulateTouch={false}
        transitionTime={500}
        centerSlidePercentage={72}
      >
        {images.map((img) => (
          <img key={img.id} src={img.image} alt="card" />
        ))}
      </Carousel>
    </div>
  );
};

const VaSubmitPopUpMobile = (Props: VaMobileProps) => {
  const { error, vaEmailInputRef, isDDG, handleVaReportSubmit } = Props;
  const [t] = useTranslation();
  return (
    <VaSubmitMainContainerMobile>
      <CarouselBlurOverlay />
      {!isDDG && (
        <VaEmailInput
          error={error}
          vaEmailInputRef={vaEmailInputRef}
          upperBanner={false}
          isDDG={isDDG}
          isMobile={true}
        />
      )}
      <Button onClick={handleVaReportSubmit} isDDG={isDDG} isMobile={true} lowerBanner={true}>
        {t('Get free Assessment')}
      </Button>
      {isDDG && (
        <StyledText error={error} top={false} upperBanner={false} isDDG={isDDG}>
          {t('Assessment will be available within 24 hours.')}
        </StyledText>
      )}
    </VaSubmitMainContainerMobile>
  );
};
export const VaLowerBannerMobile = (props: VaBannerProps): JSX.Element => {
  const { error, reportSubmited, handleVaReportSubmit, vaEmailInputRef, isDDG } = props;
  return (
    <VaLowerBannerMainContainer>
      <VaLowerBannerSecondContainer>
        <VaLowerBannerSubContainer>
          <VaBannerHeader reportSubmited={reportSubmited} />
        </VaLowerBannerSubContainer>
        <CardSlider />
      </VaLowerBannerSecondContainer>
      {reportSubmited ? (
        <VaInprogress upperBanner={false} isMobile={true} />
      ) : (
        <VaSubmitPopUpMobile
          error={error}
          vaEmailInputRef={vaEmailInputRef}
          isDDG={isDDG}
          handleVaReportSubmit={handleVaReportSubmit}
        />
      )}
    </VaLowerBannerMainContainer>
  );
};
