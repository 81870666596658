import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';

interface TabsContainerProps {
  isTabsSticky?: boolean;
}

export const TabsContainer = styled.div<TabsContainerProps>`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 16px 64px;
  column-gap: 32px;
  box-shadow: -4px 2px 8px 2px rgba(0, 0, 0, 0.15);
  position: sticky;
  z-index: 50;
  justify-content: flex-start;
  margin-top: -10px;

  @media screen and ${breakpoints.minWidth.md} {
    top: 75px;
  }

  @media screen and ${breakpoints.maxWidth.md} {
    padding: 12px;
    top: ${({ isTabsSticky }) => (!isTabsSticky ? '140px' : '75px')};
  }

  @media screen and ${breakpoints.minWidth.sm} {
    padding: 16px 64px;
    top: 75px;
  }
`;

export const Tab = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  &.selected {
    color: #0071cd;
    font-weight: 600;
  }
  &.selected::after {
    content: ' ';
    border-bottom: 2px solid #0071cd;
    position: relative;
    top: 16px;
    display: block;
    @media screen and ${breakpoints.maxWidth.md} {
      top: 12px;
    }
  }
`;
