/* eslint-disable array-bracket-newline */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import {
  areSpecialCharactersOrNumbers,
  isEmail,
  regexForEmailValidation,
  regexSpecialCharAndNumbersValidation,
} from 'utils/validator';

import {
  StyledControlInput,
  StyledMainContainer,
  StyledText,
  StyledTextError,
  RequiredTextStyled,
} from './InputTextHandler.styles';
import { useTranslation } from 'react-i18next';
import { areArraysEqual } from 'utils/helpers/arrayOperations';

export enum InputTextHandlerType {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  FIELD_NAME = 'FIELD_NAME',
}

export interface InputTextHandlerProps {
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  inputName: string;
  inputValue: string;
  inputMode?: 'text' | 'email';
  setInputValue: (val: string, id?: string | number) => void;
  showRequiredIndicator?: boolean;
  maxLength?: number;
  type: InputTextHandlerType;
  setHasErrorsForParent?:
    | Dispatch<SetStateAction<{ email: boolean }>>
    | Dispatch<SetStateAction<{ fieldName: boolean; size: boolean }>>
    | Dispatch<
        SetStateAction<{ name: boolean; email: boolean; phoneNumber: boolean; lastName: boolean }>
      >;
  requiredFailMessage?: string;
  invalidMailMessage?: string;
  invalidNameMessage?: string;
  allowClear?: boolean;
  inputProps?: { [key: string]: any };
  className?: string;
  id?: string | number;
  isLongTermMobileChange?: boolean;
}

const ErrorList = ({ errors }: { errors: string[] }) => {
  return (
    <div>
      {errors.map((error, idx) => (
        <StyledTextError key={idx} type="danger">
          {error}
        </StyledTextError>
      ))}
    </div>
  );
};

const InputTextHandler: React.FC<InputTextHandlerProps> = ({
  label,
  placeholder,
  isRequired,
  inputName,
  inputValue,
  setInputValue,
  maxLength,
  showRequiredIndicator = false,
  requiredFailMessage,
  invalidMailMessage,
  invalidNameMessage,
  type = InputTextHandlerType.FIELD_NAME,
  setHasErrorsForParent,
  inputMode,
  inputProps,
  className,
  allowClear = false,
  id,
  isLongTermMobileChange,
}) => {
  const [t] = useTranslation();
  const [inputErrors, setInputErrors] = useState<string[]>([]);
  const [inputHasChanged, setInputHasChanged] = useState<boolean>(false);
  const [lastInput, setLastInput] = useState<string>(inputValue);
  const [isSpecialChar, setIsSpecialChar] = useState<boolean>(false);

  const [cursor, setCursor] = useState<any>(null);

  const validateAndSetErrors = useCallback(
    (value: string) => {
      const errors = [];

      if (value?.length > 20 && type === InputTextHandlerType.FIELD_NAME) {
        errors.push(t('Length must be less than 20 characters'));
      }

      if (type === InputTextHandlerType.NAME && areSpecialCharactersOrNumbers(value)) {
        errors.push(
          invalidNameMessage ? invalidNameMessage : t('Input is not including only letters')
        );
      }

      if (value?.length >= 1 && type === InputTextHandlerType.EMAIL && !isEmail(value)) {
        errors.push(invalidMailMessage ? invalidMailMessage : t('Input is not a valid email'));
      }

      if (value?.length < 1 && isRequired) {
        errors.push(requiredFailMessage ? requiredFailMessage : t('This field is required'));
      }
      if (value?.length >= 1 && value?.trim() === '' && type === InputTextHandlerType.FIELD_NAME) {
        errors.push(t('Only blank Space is not allowed'));
      }

      if (areArraysEqual(inputErrors, errors)) {
        return;
      }
      setInputErrors(errors);
    },
    [inputErrors, invalidMailMessage, invalidNameMessage, isRequired, requiredFailMessage, t, type]
  );

  function containsSpecialChars(str: string) {
    // eslint-disable-next-line quotes, no-useless-escape
    const specialChars = `\`!#$%^&*()+=\[\]{};':"\\|,<>\/?~`;

    const result = specialChars.split('').some((specialChar) => {
      if (str.includes(specialChar)) {
        return true;
      }

      return false;
    });

    return result;
  }

  function detectDoubleDot(str: string) {
    for (let i = 0; i < str.length - 1; i++) {
      if (str[i] === '.' && str[i + 1] === '.') {
        return true;
      }
    }
    return false;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id?: string | number) => {
    setCursor(e.target.selectionStart);
    let stringValue = e.target.value;

    if (type === InputTextHandlerType.NAME) {
      stringValue = stringValue.replace(regexSpecialCharAndNumbersValidation, '');
    } // remove all non letters characters

    if (type === InputTextHandlerType.EMAIL) {
      if (containsSpecialChars(stringValue) || detectDoubleDot(stringValue)) {
        setIsSpecialChar(true);
      } else {
        setIsSpecialChar(false);
      }
      stringValue = stringValue.replace(regexForEmailValidation, '');
      stringValue = stringValue.replace(/[.]+/g, '.'); // if more than one consecutive dot
    }
    validateAndSetErrors(stringValue);
    setInputHasChanged(true);
    setLastInput(stringValue);

    setInputValue(stringValue, id);
  };

  useEffect(() => {
    const hasInputErrors = inputErrors.length > 0;
    if (setHasErrorsForParent) {
      setHasErrorsForParent((prev: any) => {
        const prevErrorState = prev[inputName];
        if (prevErrorState !== hasInputErrors) {
          return { ...prev, [inputName]: hasInputErrors };
        }
        return prev;
      });
    }
  }, [inputErrors, setHasErrorsForParent, inputName]);

  useEffect(() => {
    const input = document.getElementById(`input-text-handler-${inputName}`) as HTMLInputElement;
    if (input && isSpecialChar) {
      input.setSelectionRange(cursor - 1, cursor - 1);
      input.focus();
      setIsSpecialChar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, isSpecialChar]);

  useEffect(() => {
    if (inputHasChanged) {
      validateAndSetErrors(lastInput);
    }
    if (isLongTermMobileChange) {
      validateAndSetErrors(lastInput);
    }
  }, [t, inputHasChanged, lastInput, validateAndSetErrors, isLongTermMobileChange]);

  return (
    <StyledMainContainer data-testid="input-field-handler" className={className}>
      {label && (
        <StyledText>
          {label}
          {showRequiredIndicator && <RequiredTextStyled>*</RequiredTextStyled>}
        </StyledText>
      )}
      <StyledControlInput
        {...inputProps}
        type={inputMode && 'text'}
        inputMode={inputMode}
        id={`input-text-handler-${inputName}`}
        maxLength={maxLength ?? undefined}
        data-testid="input-field-text-handler"
        status={inputErrors.length > 0 ? 'error' : ''}
        value={inputValue}
        name={inputName}
        placeholder={placeholder}
        onChange={(e) => handleChange(e, id)}
        allowClear={inputErrors.length > 0 || allowClear}
        $hasErrors={inputErrors.length > 0}
        suffix={<span />} // This line is very important, otherwise the focus will be lost
      />
      {inputErrors.length > 0 && <ErrorList errors={inputErrors} />}
    </StyledMainContainer>
  );
};

export default InputTextHandler;
