/* eslint-disable react-hooks/exhaustive-deps */
import MultiFunctionMap from 'components/DrawAndDropMap/MultiFunctionMap';
import ProgressBar from 'components/ProgressBar';
import { useFlowActions } from 'context/actions/flowActions';
import { useMapActions } from 'context/actions/mapActions';
import { useTranslation } from 'react-i18next';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useAppDispatch, useAppState } from 'context/AppState';
import { Spinner } from 'components/Spinner';
import mapboxgl from 'mapbox-gl';
import { ConfirmationModal } from 'components/ConfirmationModal';
import RelocatePositionModal from 'components/RelocatePositionModal/RelocatePositionModal';
import { FlowSteps, ModeTypes, OptionType } from 'context/store/flowReducer';
import PinInformationSheet from 'components/PinInformationSheet';
import { useMap } from 'react-map-gl';
import createCircleField from 'utils/createCircleField';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useCallback, useEffect, useState } from 'react';
import { Field } from 'context/store/recommendationFormReducer';
import { FieldSoilRequest } from 'base/types/SoilType';
import { useApiDataActions } from 'context/actions/ApiDataActions';
import { getCountryCropRegion } from 'utils/constants/CountryData';
import { getUniqueSoils } from 'utils/helpers/soils';
import { pointInCountry } from 'utils/helpers/geospatial';
import { useFlags } from 'launchdarkly-react-client-sdk';
import routes from 'base/constants/routes';
import { useNavigate } from 'react-router-dom';
import DrawBoundaryInformationSheet from 'components/DrawBoundaryInformationSheet';
import DeleteNotificationModal from 'components/DeleteBoundaryNotificationModal/DeleteBoundaryNotificationModal';
import openMapNotification from 'utils/openMapNotification';
import track from 'utils/amplitudeWrapper';
import { getTranslatedFieldName } from 'utils/constants/Fields';
import { trackGTMEvent } from 'utils/createGTMEvent';

export default function MobileHome() {
  const [t] = useTranslation();
  const flowActions = useFlowActions();
  const { setUserDropPinPosition } = useMapActions();
  const navigate = useNavigate();
  const ApiDataActions = useApiDataActions();
  const recommendationFormActions = useRecommendationFormActions();
  const flags = useFlags();
  const {
    setShowPinWarning,
    setShowFieldInformationSheet,
    setIsDropAPinFlow,
    setOptionType,
    setShowDrawingWarning,
    setShowCancelBoundaryModal,
    setCurrentModeType,
    setResetBoundaryValues,
    setShowDeleteBoundaryModal,
    setDeleteBoundaryAction,
    setPreviousModeType,
    setIsDrawingBoundaries,
    setShowCropTypeDrawer,
    setShowLandIsNotArableModal,
    setCurrentStep,
    setArePinConfirmed,
    setHasDetectFieldFlowStarted,
    setFieldsMapData,
    setDetectFieldSelectedIds,
    setSelectedFieldMapData,
  } = flowActions;
  const {
    flow: {
      showPinWarning,
      showSpinner,
      showLandIsNotArableModal,
      currentStep,
      showFieldInformationSheet,
      optionType,
      currentModeType,
      showDrawingWarning,
      showCancelBoundaryModal,
      resetBoundaryValues,
      showDeleteBoundaryModal,
      deleteBoundaryAction,
      isDropAPinFlow,
      hasDetectFieldFlowStarted,
      detectFieldSelectedIds,
      fieldsMapData,
      selectedFieldMapData,
      localCountry,
    },
    recommendationForm: {
      pin,
      fieldSelected,
      fields,
      countryCode,
      tempBoundaryField,
      tempFields,
      fieldIdToEdit,
    },
    apiData: {
      fieldsAttributesLoading,
      recommenationValidateLoading,
      arableLandLoading,
      formattedDetectFieldsData,
    },
    map: { userDropPinPosition, ipApiLoading },
    apiData: { fieldSoils },
  } = useAppState();
  const {
    setTempBoundaryField,
    deleteField: deleteFieldById,
    setFieldSelected,
    saveFields,
    setIsDeletingTempField,
    setFieldIdToEdit,
    setFieldEditId,
  } = useRecommendationFormActions();
  const appDispatcher = useAppDispatch();
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [pinSize, setPinSize] = useState('12');
  type FieldSoilKey = keyof typeof fieldSoils;
  const { flowMap, flowMapMobile } = useMap();
  const map = flowMap ?? flowMapMobile;

  useEffect(() => {
    if (optionType) {
      setIsLandingPage(false);
    } else {
      setIsLandingPage(true);
    }
  }, [optionType]);

  useEffect(() => {
    if (hasDetectFieldFlowStarted && optionType === OptionType.Draw && fields.length > 0) {
      setHasDetectFieldFlowStarted({ hasDetectFieldFlowStarted: false });
    }
  }, [fields.length]);

  useEffect(() => {
    if (
      fields.length === 0 &&
      !tempBoundaryField &&
      ![OptionType.Detect, OptionType.empty].includes(optionType)
    ) {
      setCurrentModeType({ modeType: ModeTypes.CREATING });
    }
  }, [fields, tempBoundaryField, optionType]);

  const onOkRelocatePinModal = () => {
    setShowPinWarning({ show: false });
    const position = new mapboxgl.LngLat(0, 0);
    setUserDropPinPosition({ position });
  };
  const handleShowLandNotArableModal = () => {
    setShowLandIsNotArableModal({ show: false });
    if (isDropAPinFlow) {
      const position = new mapboxgl.LngLat(0, 0);
      setUserDropPinPosition({ position });
    } else {
      setTempBoundaryField({ boundary: undefined });
      setOptionType({ type: OptionType.Draw });
      setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
    }
  };

  const handleCancelClick = useCallback(() => {
    setShowFieldInformationSheet({ showFieldInformationSheet: false });
    setIsDropAPinFlow({ isDropAPin: false });
    setOptionType({ type: OptionType.empty });
    appDispatcher({ type: 'reset-app' });
  }, [showFieldInformationSheet]);

  const handleAddAnotherClick = () => {
    setShowFieldInformationSheet({ showFieldInformationSheet: false });
    setOptionType({ type: OptionType.Draw });
    setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
  };

  const handleAddAnotherClickDetectField = () => {
    setShowFieldInformationSheet({ showFieldInformationSheet: false });
    setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
  };

  const handleDrawerClose = useCallback(() => {
    if (optionType === OptionType.Detect && currentModeType !== ModeTypes.NO_ACTION) {
      setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
    }
    setShowFieldInformationSheet({ showFieldInformationSheet: false });
  }, [showFieldInformationSheet, currentModeType, optionType]);

  useEffect(() => {
    if (!showFieldInformationSheet && tempBoundaryField && currentModeType !== ModeTypes.EDITING) {
      const allFields: Field[] = fields.filter(
        (item) => item.id !== tempBoundaryField.properties?.id
      );
      saveFields({ fields: allFields });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModeType, showFieldInformationSheet]);

  const onOkRelocateBoundaryModal = useCallback(() => {
    setShowDrawingWarning({ show: false });
  }, []);

  const handleGoBackModalConfirm = () => {
    setShowCancelBoundaryModal({ show: false });
    setOptionType({ type: OptionType.empty });
    appDispatcher({ type: 'reset-app' });
    setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
    if (currentModeType === ModeTypes.CREATING) {
      setTempBoundaryField({ boundary: undefined });
      setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
    } else {
      //In case of step 2
    }
  };

  const handleCancelBoundaryModalConfirm = () => {
    setShowCancelBoundaryModal({ show: false });
    setOptionType({ type: OptionType.Detect });
    setTempBoundaryField({ boundary: undefined });
    setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
    setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
  };
  const getCircleSoils = useCallback(
    async ({ circleBoundary, countryCode }: { circleBoundary: Field; countryCode: string }) => {
      const fieldsDrawed = [
        {
          id: circleBoundary.id,
          geometry: {
            type: circleBoundary.boundary.geometry.type,
            coordinates: [...circleBoundary.boundary.geometry.coordinates],
          },
        },
      ] as FieldSoilRequest[];
      const soilsFetched = await ApiDataActions.getFieldSoils(
        fieldsDrawed,
        getCountryCropRegion(countryCode),
        'SYNGENTA',
        false
      );

      const fieldId = circleBoundary.id;
      const circleSoils = soilsFetched.soilGrid[fieldId];

      return {
        ...circleBoundary,
        soils: circleSoils,
        selectedSoils: getUniqueSoils({ soils: circleSoils }),
        fieldsResolution: soilsFetched.fieldsResolution,
      };
    },
    [ApiDataActions]
  );

  const verifySoils = async (fieldsData?: Field[] | undefined) => {
    let fieldInfo;
    const savedFieldData = fields.map((item) => {
      return {
        ...item,
        isSaved: true,
      };
    });
    if (fieldsData) {
      fieldInfo =
        savedFieldData.length >= 1 && fieldsData ? [...savedFieldData, ...fieldsData] : fieldsData;
    } else {
      fieldInfo = savedFieldData;
    }
    const areSoilsMissing = fieldInfo?.some((item) => !item.soils);
    if (areSoilsMissing) {
      const fieldsDrawed = fieldInfo?.map((item) => ({
        id: `${item.id}`,
        geometry: {
          type: item.boundary.geometry.type,
          coordinates: [...item.boundary.geometry.coordinates],
        },
      }));
      const soilsFetched =
        fieldsDrawed &&
        (await ApiDataActions.getFieldSoils(
          fieldsDrawed,
          getCountryCropRegion(countryCode),
          'SYNGENTA',
          false
        ));
      if (soilsFetched) {
        const fieldsWithSoils = fieldInfo?.map((item) => {
          const fieldId = item.id as FieldSoilKey;
          const itemSoils = soilsFetched.soilGrid[fieldId];
          let itemSelectedSoils = item.selectedSoils;
          if (!itemSelectedSoils || itemSelectedSoils?.length === 0) {
            // selecting default soils
            itemSelectedSoils = getUniqueSoils({ soils: itemSoils });
          }

          return {
            ...item,
            soils: itemSoils,
            selectedSoils: itemSelectedSoils,
            fieldsResolution: soilsFetched.fieldsResolution,
          };
        });
        if (fieldsWithSoils) {
          recommendationFormActions.saveFields({ fields: [...fieldsWithSoils] });
        }
      }
    }
  };

  const handleNextClickDrawBoundary = async () => {
    const boundaryCount = fields.filter((item) => !item.isDetectedField).length;
    track('confirm boundary created', { 'boundaries added count': boundaryCount });
    setShowFieldInformationSheet({ showFieldInformationSheet: false });
    setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
    navigate(routes.wizardMobile);
    verifySoils();
    setShowCropTypeDrawer({ show: true });
    setCurrentStep({ step: FlowSteps.STEP2 });
    trackGTMEvent('confirm_boundary_created', countryCode, localCountry);
  };

  const handleNextClickDetectBoundary = async () => {
    track('confirm boundary selected', {
      'boundary selected': true,
      'boundaries selected count': fields.length,
    });
    trackGTMEvent('confirm_boundary_selected', countryCode, localCountry);
    setShowFieldInformationSheet({ showFieldInformationSheet: false });
    setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
    navigate(routes.wizardMobile);
    verifySoils();
    setShowCropTypeDrawer({ show: true });
    setCurrentStep({ step: FlowSteps.STEP2 });
  };

  const handleNextClickDropAPin = async () => {
    setShowFieldInformationSheet({ showFieldInformationSheet: false });
    navigate(routes.wizardDropAPinMobile);
    if (map) {
      const code = pointInCountry(userDropPinPosition, flags) || '';
      map.zoomTo(map.getZoom() < 12 ? 12 : map.getZoom());
      const center = [userDropPinPosition.lng, userDropPinPosition.lat];
      const circleBoundary = createCircleField(center);
      circleBoundary.properties = { center: center, id: 1 };
      recommendationFormActions.setPinPosition({ lngLat: userDropPinPosition });
      console.log('countryCode MobileHome', countryCode);
      recommendationFormActions.setCountryCode({ countryCode: code });

      recommendationFormActions.setPinHectaresSize({
        hectaresSize: Number.parseFloat(pinSize),
      });
      setArePinConfirmed({ arePinConfirmed: true });
      setCurrentStep({ step: FlowSteps.STEP2 });
      setShowCropTypeDrawer({ show: true });
      const circleBoundaryWithSoils = await getCircleSoils({
        countryCode: code,
        circleBoundary: {
          fieldName: 'Field 1',
          hectares: '12',
          boundary: circleBoundary,
          id: 1,
        },
      });
      recommendationFormActions.saveFields({
        fields: [circleBoundaryWithSoils],
      });
    }
  };
  const deleteField = useCallback(() => {
    if (fieldSelected) {
      if (fieldSelected.id === tempBoundaryField?.properties?.id) {
        setIsDeletingTempField({ isDeletingTempField: true });
      } else {
        setIsDeletingTempField({ isDeletingTempField: false });
      }
      deleteFieldById({ fieldId: fieldSelected.id });
      const uniqueId = fieldSelected.detectFielduniqueId;
      if (uniqueId) {
        setDetectFieldSelectedIds({
          data: detectFieldSelectedIds.filter((item) => item !== uniqueId),
        });
        setSelectedFieldMapData({
          data: {
            type: 'FeatureCollection',
            features: selectedFieldMapData.features.filter(
              (item) => item.properties.fieldUniqueId !== uniqueId
            ),
            isDigifarm: formattedDetectFieldsData.isDigifarm,
          },
        });
      }
      setFieldsMapData({
        data: {
          type: 'FeatureCollection',
          features: fieldsMapData.features.filter(
            (item) => Number(item.id) !== Number(fieldSelected.id)
          ),
          isDigifarm: formattedDetectFieldsData.isDigifarm,
        },
      });
    } else if (fieldIdToEdit) {
      const updatedFieldList = tempFields.filter((item) => item.id !== fieldIdToEdit);
      setFieldsMapData({
        data: {
          type: 'FeatureCollection',
          features: fieldsMapData.features.filter(
            (item) => Number(item.id) !== Number(fieldIdToEdit)
          ),
          isDigifarm: formattedDetectFieldsData.isDigifarm,
        },
      });
      recommendationFormActions.saveFields({ fields: updatedFieldList });
      setTempBoundaryField({ boundary: undefined });
      setIsDeletingTempField({ isDeletingTempField: true });
    }
  }, [fieldSelected, fieldIdToEdit]);

  const getFieldName = (field: Field[]) => {
    if (fieldIdToEdit) {
      return tempFields.find((item) => item.id === fieldIdToEdit)?.fieldName ?? '';
    }
    return field ? `${t('Field')} ${field.length + 1}` : 'Field 1';
  };

  const displayDeletedFieldNotification = useCallback(() => {
    const fieldName = getTranslatedFieldName({
      defaultName: fieldSelected ? fieldSelected.fieldName : getFieldName(fields) ?? '',
      t,
    });
    openMapNotification({
      id: 'boundaryDeleted',
      className: 'toast-notification-with-icon',
      placement: 'top',
      duration: 5,
      msg: 'fieldDeletion',
      msgProps: { fieldName },
      width: 250,
    });
  }, [fieldSelected, fields]);

  const setNextModeType = useCallback(() => {
    if (fieldIdToEdit) {
      setCurrentModeType({ modeType: ModeTypes.EDITING });
      setFieldIdToEdit({ fieldId: '' });
      setFieldEditId({ fieldId: '' });
    } else if (currentModeType !== ModeTypes.EDITING && optionType !== OptionType.Detect) {
      setCurrentModeType({ modeType: ModeTypes.CREATING });
    }
  }, [setCurrentModeType, fieldIdToEdit, optionType]);

  const handleDeleteFieldFromSheetOnConfirm = useCallback(() => {
    setShowDeleteBoundaryModal({ show: false });
    deleteField();
    displayDeletedFieldNotification();
    setFieldSelected({ field: undefined });
    setNextModeType();
    setDeleteBoundaryAction({ deleteBoundaryAction: !deleteBoundaryAction });
    setPreviousModeType({ modeType: ModeTypes.CREATING });
    if ((tempFields.length > 1 || fields.length > 1) && currentModeType !== ModeTypes.CREATING) {
      setShowFieldInformationSheet({ showFieldInformationSheet: true });
    }
  }, [
    fields,
    deleteBoundaryAction,
    currentModeType,
    setNextModeType,
    setFieldSelected,
    deleteField,
    displayDeletedFieldNotification,
    tempBoundaryField,
    tempFields,
  ]);

  const handleDeleteFieldFromSheetOnCancel = useCallback(() => {
    if (currentModeType !== ModeTypes.EDITING && optionType !== OptionType.Detect) {
      setIsDrawingBoundaries({ isDrawing: true });
      setCurrentModeType({ modeType: ModeTypes.CREATING });
      setFieldSelected({ field: undefined });
    }
    setShowDeleteBoundaryModal({ show: false });
    if (!tempBoundaryField && currentModeType !== ModeTypes.CREATING && fields.length > 0) {
      setShowFieldInformationSheet({ showFieldInformationSheet: true });
    }
  }, [
    optionType,
    fields,
    tempBoundaryField,
    currentModeType,
    setCurrentModeType,
    setFieldSelected,
    setIsDrawingBoundaries,
    setShowDeleteBoundaryModal,
    setShowFieldInformationSheet,
  ]);

  return (
    <>
      {(showSpinner ||
        recommenationValidateLoading ||
        fieldsAttributesLoading ||
        arableLandLoading ||
        ipApiLoading) && <Spinner />}
      <ProgressBar
        isLandingPage={isLandingPage}
        handleNextAction={() => undefined}
        handlePreviousAction={() => undefined}
        showProgressLine={true}
      />
      {showPinWarning && (
        <RelocatePositionModal
          title={t('Relocate position of the pin')}
          text={t('No products available for this location. Please use another location.')}
          notificationColor={colors.yellow40}
          onOkClick={onOkRelocatePinModal}
        />
      )}
      {showDrawingWarning && (
        <RelocatePositionModal
          title={t('Relocate position of the boundary')}
          text={t('No products available for this location. Please use another location.')}
          onOkClick={onOkRelocateBoundaryModal}
        />
      )}
      {showCancelBoundaryModal &&
        (hasDetectFieldFlowStarted ? (
          <ConfirmationModal
            title={t('Cancel Boundary')}
            body={t(
              'Are you sure you would like to navigate away from this page, all progress for this boundary will be lost.'
            )}
            cancelButtonText={t('No, remain')}
            confirmButtonText={t('Yes, cancel')}
            onClickConfirm={handleCancelBoundaryModalConfirm}
            onClickCancel={() => {
              setShowCancelBoundaryModal({ show: false });
            }}
          />
        ) : (
          <ConfirmationModal
            title={t('Exit Draw Boundary')}
            body={t(
              'Are you sure you want to navigate away from this page? All progress will be lost.'
            )}
            cancelButtonText={t('No, remain')}
            confirmButtonText={t('Yes, exit')}
            onClickConfirm={handleGoBackModalConfirm}
            onClickCancel={() => {
              setShowCancelBoundaryModal({ show: false });
            }}
          />
        ))}
      {showLandIsNotArableModal && (
        <ConfirmationModal
          title={t('Field not on arable land')}
          body={
            isDropAPinFlow
              ? t(
                'Looks like your field is not on arable land. Please change the location of the pin.'
              )
              : t('Looks like your field is not on arable land. Please redraw the boundary.')
          }
          confirmButtonText={t('Ok')}
          onClickConfirm={handleShowLandNotArableModal}
        />
      )}
      {currentStep === FlowSteps.STEP1 && !ipApiLoading && <MultiFunctionMap id="flowMapMobile" />}
      {showFieldInformationSheet && optionType === OptionType.Drop && (
        <PinInformationSheet
          title={t(`Confirm pin location`)}
          onClickCancel={handleCancelClick}
          onClickNext={handleNextClickDropAPin}
          onClickOutside={handleDrawerClose}
          setPinSize={setPinSize}
          defaultSize={pin.hectaresSize?.toString()}
        />
      )}
      {showFieldInformationSheet && optionType === OptionType.Draw && fields.length ? (
        <DrawBoundaryInformationSheet
          title={t(`Save Boundaries`)}
          onClickCancel={handleAddAnotherClick}
          onClickNext={handleNextClickDrawBoundary}
          onClickOutside={handleDrawerClose}
        />
      ) : null}
      {showFieldInformationSheet &&
        optionType === OptionType.Detect &&
        (detectFieldSelectedIds.length > 0 || fields.length > 0) && (
        <DrawBoundaryInformationSheet
          title={
            detectFieldSelectedIds.length > 1 ? t(`Confirm Boundaries`) : t(`Confirm Boundary`)
          }
          onClickCancel={handleAddAnotherClickDetectField}
          onClickNext={handleNextClickDetectBoundary}
          onClickOutside={handleDrawerClose}
        />
      )}
      {showDeleteBoundaryModal && (
        <DeleteNotificationModal
          handleCancelClick={() => handleDeleteFieldFromSheetOnCancel()}
          handleConfirmClick={() => handleDeleteFieldFromSheetOnConfirm()}
          isConfirmButtonDisabled={false}
          fieldName={fieldSelected?.fieldName ?? getFieldName(fields)}
        />
      )}
    </>
  );
}
