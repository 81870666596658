import { track as customTrack } from '@amplitude/analytics-browser';
import Cookies from 'js-cookie';

const track = (eventName: string, eventData: object) => {
  const isBotUser = Cookies.get('isBotUser') === 'true';
  const urlParams = new URLSearchParams(window.location.search);
  const isTrackingOff = urlParams.get('track') === 'off';
  return !isBotUser && !isTrackingOff && customTrack(eventName, eventData);
};

export default track;
