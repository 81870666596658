import styled from 'styled-components';
import { colors, Divider, Row } from 'syngenta-digital-cropwise-react-ui-kit';
import ModalView from 'components/ModalView';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';
import breakpoints from 'base/constants/breakpoints';

export const StyledModalView = styled(ModalView)<IStyledModalProps>`
  ${(props) => showSlidingAnimation(props.slideDirection)}
  background-color: #000000;
  color: #ffffff;
  @media screen and (orientation: landscape) {
    max-width: 50%;
    right: 0;
    top: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 100%;
    max-height: 100%;
  }
  .iMmxge {
    border: none;
  }
  .modal-view-content {
    gap: 8px;
  }
  .modal-view-footer {
    padding-top: 10px;
  }
  .modal-view-header {
    font-size: 20px;
    color: #ffffff;
  }
`;

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
`;

export const StyledDivText = styled('div')`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;

export const StyledDivValue = styled('div')`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  line-height: 24px;
  color: #ffffff;
`;

export const StyledYearSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

interface CustomButtonProps {
  isSelected: boolean;
}
export const StyledYearButton = styled.button<CustomButtonProps>`
  padding: 2px 8px;
  border: 1px solid #f3f4f6;
  background-color: #f3f4f6;
  color: #696f88;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Noto Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.09px;

  ${({ isSelected }) =>
    isSelected &&
    `
    border-radius: 4px;
    border: 1px solid var(--Blue-blue-20, #C6E6FF);
    background: var(--Blue-blue-20, #C6E6FF);
    
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  `}

  &:last-child,
  &:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 16px 0px;
  width: 100%;
  @media screen and ${breakpoints.maxWidth.sm} {
    height: 1px;
    background-color: ${colors.neutral60};
    width: 100%;
    margin-top: 4px;
    margin-bottom: 5px;
  }
`;

export const StyledPopup = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledProductName = styled('div')`
  font-family: 'Noto Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.15px;
`;
