import { featureCollection, center, feature } from '@turf/turf';
import { Field as DDGField } from 'context/store/recommendationFormReducer';
import * as turf from '@turf/turf';
import { Field } from 'base/types/RecommendationCropwise';

export const getCentroidOfFields = (fields: DDGField[]) => {
  if (fields.length > 0) {
    const featureBoundary = fields.map((item) => item.boundary);
    // combine all of our field geometry into a feature collection
    const collection = featureCollection(
      featureBoundary.filter((field) => !!field?.geometry).map((field) => feature(field?.geometry))
    );
    const centerPointFeature = center(collection);
    if (centerPointFeature?.geometry) {
      const centroidCoords = [centerPointFeature.geometry.coordinates[1], centerPointFeature.geometry.coordinates[0]];
      return centroidCoords;
    }
  };
  return [];
};

export const getCentroidForCircle = (fields: DDGField[]) => {
  if (fields[0]?.boundary?.properties?.center) {
    return [fields[0]?.boundary?.properties?.center[1], fields[0]?.boundary?.properties?.center[0]];
  } else {
    return [];
  }
};

export const findCentroidOfAllFieldsInRec = (fields: Field[]): any => {
  // Extract all polygons and multipolygons into a single array
  const polygons: turf.AllGeoJSON[] = [];

  fields.forEach((field) => {
    if (field.geometry.type === 'Polygon') {
      polygons.push(turf.polygon(field.geometry.coordinates));
    } else if (field.geometry.type === 'MultiPolygon') {
      field.geometry.coordinates.forEach((polygonCoords: any) => {
        polygons.push(turf.polygon(polygonCoords));
      });
    }
  });

  if (polygons.length === 0) {
    console.error('No valid polygons found.');
    return null;
  }
  // Combine all polygons into a single MultiPolygon feature
  const multiPolygon = turf.multiPolygon(polygons.map((p: any) => p.geometry.coordinates));

  // Calculate the centroid of the MultiPolygon
  const centroid = turf.centroid(multiPolygon);
  return centroid;
};
