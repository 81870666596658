import {
  bboxPolygon,
  lineString,
  bbox,
  booleanPointInPolygon,
  point,
  polygon,
  booleanWithin,
  lineIntersect,
  kinks,
  intersect,
  area,
  centroid,
  multiPolygon,
  pointsWithinPolygon,
} from '@turf/turf';
import { Feature, Polygon, Properties } from '@turf/helpers';

import { LngLat, LngLatBounds } from 'mapbox-gl';

import Poland from 'base/geojson/Poland.json';
import Romania from 'base/geojson/Romania.json';
import Hungary from 'base/geojson/Hungary.json';
import Brazil from 'base/geojson/Brazil.json';
import Bulgaria from 'base/geojson/Bulgaria.json';
import Germany from 'base/geojson/Germany.json';
import Italy from 'base/geojson/Italy.json';
import Ukraine from 'base/geojson/Ukraine.json';
import SouthAfrica from 'base/geojson/South_Africa.json';
import Belarus from 'base/geojson/Belarus.json';
import Russia from 'base/geojson/Russia.json';
import Serbia from 'base/geojson/Serbia.json';
import Portugal from 'base/geojson/Portugal.json';
import Spain from 'base/geojson/Spain.json';
import Kazakhstan from 'base/geojson/Kazakhstan.json';
import England from 'base/geojson/England.json';
import { Field } from 'context/store/recommendationFormReducer';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';

const TARGET_COUNTRIES = [Poland, Romania, Hungary, Bulgaria, Serbia, Belarus];
const TARGET_COUNTRIES_MULTIPOLYGON = [
  Brazil,
  Germany,
  Ukraine,
  Italy,
  SouthAfrica,
  Russia,
  Portugal,
  England,
];
const COUNTRIES_WITH_MULTIPOLYGON = [
  'BRA',
  'DEU',
  'UKR',
  'ITA',
  'ZAF',
  'BRZ',
  'RUS',
  'PRT',
  'ESP',
  'KAZ',
  'GBR',
];

export enum TARGET_COUNTRIES_CODES {
  POL = 'POL',
  ROU = 'ROU',
  HUN = 'HUN',
  BRZ = 'BRZ',
  DEU = 'DEU',
  UKR = 'UKR',
  BRA = 'BRA',
  ITA = 'ITA',
  BGR = 'BGR',
  ZAF = 'ZAF',
  BLR = 'BLR',
  RUS = 'RUS',
  SRB = 'SRB',
  PRT = 'PRT',
  ESP = 'ESP',
  KAZ = 'KAZ',
  GBR = 'GBR',
}
export interface PointCoordinates {
  lng: number;
  lat: number;
}

/**
 * Validates whether a point is inside a bounding box
 * @param  {LngLat} mapboxPoint Point coordinates
 * @param  {LngLatBounds} mapboxBbox Map bounding box
 * @return {boolean} Returns true if point is within the bounding box
 */
export const pointInBbox = (mapboxPoint: LngLat, mapboxBbox: LngLatBounds): boolean => {
  const northEast = mapboxBbox.getNorthEast();
  const southWest = mapboxBbox.getSouthWest();

  const line = lineString([
    [southWest.lng, southWest.lat],
    [northEast.lng, northEast.lat],
  ]);
  const turfBbox = bbox(line);

  const turfPoint = point([mapboxPoint.lng, mapboxPoint.lat]);
  const turfPolygon = bboxPolygon(turfBbox);

  return booleanPointInPolygon(turfPoint, turfPolygon);
};

/**
 * Sending the coordinates of map viewable in the screen
 * @param  {LngLatBounds} mapboxBbox Map bounding box
 * @return {Polygon} Returns coordinates of a biggest polygon present on the screen
 */

export const currentMapCoordinates = (mapboxBbox: LngLatBounds): Feature<Polygon, Properties> => {
  const northEast = mapboxBbox.getNorthEast();
  const southWest = mapboxBbox.getSouthWest();
  const line = lineString([
    [southWest.lng, southWest.lat],
    [northEast.lng, northEast.lat],
  ]);
  const turfBbox = bbox(line);
  const turfPolygon = bboxPolygon(turfBbox);
  return turfPolygon;
};

/**
 * Validates whether a point is inside any Target Country
 * @param  {PointCoordinates} object {lng,lat} Point coordinates
 * @return {string} Returns Country Code if point is inside a target country
 */

export const isCountryFlagEnabled = (
  code: string | undefined,
  flags: LDFlagSet
): boolean | undefined => {
  const countryEnableData = [
    { countryCode: 'ROU', isEnabled: true },
    { countryCode: 'DEU', isEnabled: true },
    { countryCode: 'ITA', isEnabled: true },
    { countryCode: 'HUN', isEnabled: true },
    { countryCode: 'POL', isEnabled: true },
    { countryCode: 'BRZ', isEnabled: true },
    { countryCode: 'BGR', isEnabled: true },
    { countryCode: 'UKR', isEnabled: true },
    { countryCode: 'ZAF', isEnabled: true },
    { countryCode: 'GBR', isEnabled: true },
  ];
  return countryEnableData?.find((country) => country.countryCode === code)?.isEnabled;
};

export const pointInCountry = (
  { lng, lat }: PointCoordinates,
  flags: LDFlagSet,
  isDetectField = false
): string | undefined => {
  const turfPoint = point([lng, lat]);
  let countryName: string | undefined;

  TARGET_COUNTRIES.some((country) => {
    const countryPolygon = polygon(country.geometry.coordinates);
    if (booleanPointInPolygon(turfPoint, countryPolygon)) {
      countryName = country.properties.sov_a3;
      return true;
    }
  });
  TARGET_COUNTRIES_MULTIPOLYGON.some((country) => {
    const countryMultiPolygon = multiPolygon(country.geometry.coordinates);
    const countryObj = pointsWithinPolygon(turfPoint, countryMultiPolygon);
    if (countryObj?.features.length > 0) {
      countryName = country.properties.sov_a3;
    }
  });
  if (isDetectField || isCountryFlagEnabled(countryName, flags)) {
    return countryName;
  }
  console.log('countryName pointInCountry', countryName);
  return undefined;
};

/**
 * Validates whether a point belongs to specific Target Country
 * @param  {PointCoordinates} object {lng,lat} Point coordinates
 * @param  {string} countryCode country code
 * @return {boolean} Returns a boolean that tell us if the point belongs to the country
 */

export const pointIsInSpecificCountry = ({ lng, lat }: PointCoordinates, countryCode: string) => {
  const turfPoint = point([lng, lat]);

  switch (countryCode) {
    case TARGET_COUNTRIES_CODES.POL:
      return booleanPointInPolygon(turfPoint, polygon(Poland.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.ROU:
      return booleanPointInPolygon(turfPoint, polygon(Romania.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.HUN:
      return booleanPointInPolygon(turfPoint, polygon(Hungary.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.BRZ:
      return booleanPointInPolygon(turfPoint, multiPolygon(Brazil.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.DEU:
      return booleanPointInPolygon(turfPoint, multiPolygon(Germany.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.ITA:
      return booleanPointInPolygon(turfPoint, multiPolygon(Italy.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.BGR:
      return booleanPointInPolygon(turfPoint, polygon(Bulgaria.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.UKR:
      return booleanPointInPolygon(turfPoint, multiPolygon(Ukraine.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.ZAF:
      return booleanPointInPolygon(turfPoint, multiPolygon(SouthAfrica.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.BLR:
      return booleanPointInPolygon(turfPoint, polygon(Belarus.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.RUS:
      return booleanPointInPolygon(turfPoint, multiPolygon(Russia.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.SRB:
      return booleanPointInPolygon(turfPoint, polygon(Serbia.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.PRT:
      return booleanPointInPolygon(turfPoint, multiPolygon(Portugal.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.ESP:
      return booleanPointInPolygon(turfPoint, multiPolygon(Spain.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.KAZ:
      return booleanPointInPolygon(turfPoint, multiPolygon(Kazakhstan.geometry.coordinates));
    case TARGET_COUNTRIES_CODES.GBR:
      return booleanPointInPolygon(turfPoint, multiPolygon(England.geometry.coordinates));
    default:
      return false;
  }
};

/**
 * Validates whether a polygon is inside any Target Country
 * @param  {PointCoordinates} polygonCordinates Polygon well closed coordinates
 * @return {boolean}  Returns true if whole polygon is within any Target country
 */
export const polygonInsideCountry = (
  polygonCordinates: Array<[number, number]>,
  countryCode: string | undefined,
  isBrazilEnable: boolean
) => {
  const drawnPolygon = polygon([[...polygonCordinates]]);
  const isWithinCountryBoundary: boolean[] = [];

  TARGET_COUNTRIES.forEach((country) => {
    const countryPolygon = polygon(country.geometry.coordinates);
    isWithinCountryBoundary.push(booleanWithin(drawnPolygon, countryPolygon));
  });
  TARGET_COUNTRIES_MULTIPOLYGON.forEach((country) => {
    const countryMultiPolygon = multiPolygon(country.geometry.coordinates);
    isWithinCountryBoundary.push(booleanWithin(drawnPolygon, countryMultiPolygon));
  });
  return isWithinCountryBoundary?.some((item) => item);
};

/**
 * Validates whether two lines are overlapping each other
 * @param  {[number, number][]} oldLine First line coordinates
 * @param  {[number, number][]} newLine New line coordinates
 * @return {boolean}  Returns true if lines are intersecting MORE than once
 */
export const isLineStringOverlaping = ({
  oldLine,
  newLine,
}: {
  oldLine: [number, number][];
  newLine: [number, number][];
}) => {
  const currentPolyline = lineString(oldLine);
  const newPolyline = lineString(newLine);
  const areIntersectsBetweenPolylines = lineIntersect(currentPolyline, newPolyline);
  return areIntersectsBetweenPolylines.features.length > 1;
};

/**
 * Validates whether a polygon is SELF intersecting
 * @param  {Array<[number, number]>} polygonCoordinates Polygon well closed
 * @return {boolean}  Returns true if polygon is self intersecting
 */
export const isPolygonOverlaping = ({
  polygonCoordinates,
}: {
  polygonCoordinates: Array<[number, number]>;
}) => {
  const { features } = kinks(polygon([polygonCoordinates]));
  return features.length !== 0;
};

/**
 * Validates whether a point is inside any provided polygons
 * @param  {PointCoordinates} object {lng,lat} Point coordinates
 * @param  {Field[]} polygonArray Polygons well closed
 * @return {boolean}  Returns true if point is inside any provided polygons
 */
export const pointInPolygons = ({ lng, lat }: PointCoordinates, polygonArray: Field[]) => {
  const turfPoint = point([lng, lat]);

  return polygonArray.some((polygonItem) => {
    const turfPolygon = polygon([...polygonItem.boundary.geometry.coordinates]);
    return booleanPointInPolygon(turfPoint, turfPolygon);
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const findFieldToEdit = ({ lng, lat }: PointCoordinates, polygonArray: Field[]) => {
  const turfPoint = point([lng, lat]);
  return polygonArray.find((polygonItem) => {
    const turfPolygon = polygon([...polygonItem.boundary.geometry.coordinates]);
    return booleanPointInPolygon(turfPoint, turfPolygon);
  });
};

/**
 * Validates whether a line is intersecting another provided polygon
 * @param  {[number, number][]} line Line coordinates
 * @param  {Field[]} polygonArray Polygons well closed coordinates
 * @return {boolean}  Returns true if line intersects any polygon
 */
export const isLineOverlappingPolygons = ({
  line,
  polygonArray,
}: {
  line: [number, number][];
  polygonArray: Field[];
}) => {
  const currentPolyline = lineString(line);

  return polygonArray.some((polygonItem) => {
    const turfPolygon = polygon([...polygonItem.boundary.geometry.coordinates]);
    const intersection = lineIntersect(currentPolyline, turfPolygon);
    return intersection.features.length > 0;
  });
};

/**
 * Validates whether a polygon is contained/within or intersects another provided polygon
 * @param  {Array<[number, number]>} polygonCordinates Polygon well closed
 * @param  {Field[]} polygonArray Polygons well closed
 * @return {boolean}  Returns true if any provided polygon is contained or intersects polygonCordinates
 */
export const polygonsOverlaps = (
  polygonCordinates: Array<[number, number]>,
  polygonArray: Field[]
) => {
  const drawnPolygon = polygon([[...polygonCordinates]]);

  return polygonArray.some((polygonItem) => {
    const turfPolygon = polygon(polygonItem.boundary.geometry.coordinates);
    const resultWithin = booleanWithin(turfPolygon, drawnPolygon);
    const resultIntersect = intersect(turfPolygon, drawnPolygon);
    return resultWithin || resultIntersect !== null;
  });
};

/**
 * @param  {Array<[number, number]>} polygonCordinates Polygon to be calculated it's area
 * * @return { number }  Returns the area in hectares rouned by three decimals
 */
export const calculateAreaOfPolygon = (polygonCordinates: Array<[number, number]>) => {
  const drawnPolygon = polygon([[...polygonCordinates]]);
  const squareMetters = area(drawnPolygon);
  const hectares = squareMetters / 10000;

  return hectares.toFixed(3);
};

export const getCountryPostalCode = (countryCode: string | undefined): string => {
  if (!countryCode) {
    return '';
  }
  let postalCode: string;
  if (COUNTRIES_WITH_MULTIPOLYGON.includes(countryCode)) {
    postalCode =
      TARGET_COUNTRIES_MULTIPOLYGON?.find((data) => data.properties.sov_a3 === countryCode)
        ?.properties.postal ?? '';
  } else {
    postalCode =
      TARGET_COUNTRIES?.find((data) => data.properties.sov_a3 === countryCode)?.properties.postal ??
      '';
  }
  return postalCode?.toLocaleLowerCase();
};

export const getCentroidByCountry = (countryCode: string) => {
  let countryCentroid;
  switch (countryCode) {
    case TARGET_COUNTRIES_CODES.POL:
      countryCentroid = centroid(polygon(Poland.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.ROU:
      countryCentroid = centroid(polygon(Romania.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.HUN:
      countryCentroid = centroid(polygon(Hungary.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.BRA:
      countryCentroid = centroid(multiPolygon(Brazil.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.DEU:
      countryCentroid = centroid(multiPolygon(Germany.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.ITA:
      countryCentroid = centroid(multiPolygon(Italy.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.BGR:
      countryCentroid = centroid(polygon(Bulgaria.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.UKR:
      countryCentroid = centroid(multiPolygon(Ukraine.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.ZAF:
      countryCentroid = centroid(multiPolygon(SouthAfrica.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.BLR:
      countryCentroid = centroid(polygon(Belarus.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.RUS:
      countryCentroid = centroid(multiPolygon(Russia.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.SRB:
      countryCentroid = centroid(polygon(Serbia.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.PRT:
      countryCentroid = centroid(multiPolygon(Portugal.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.ESP:
      countryCentroid = centroid(multiPolygon(Spain.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.KAZ:
      countryCentroid = centroid(multiPolygon(Kazakhstan.geometry.coordinates));
      break;
    case TARGET_COUNTRIES_CODES.GBR:
      countryCentroid = centroid(multiPolygon(England.geometry.coordinates));
      break;
    default:
      countryCentroid = centroid(polygon(Poland.geometry.coordinates));
  }
  return countryCentroid;
};

export const getZoomLevelByCountry = (countryCode: string) => {
  let countryZoom;
  switch (countryCode) {
    case TARGET_COUNTRIES_CODES.POL:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_POLAND;
      break;
    case TARGET_COUNTRIES_CODES.ROU:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_ROMANIA;
      break;
    case TARGET_COUNTRIES_CODES.HUN:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_HUNGARY;
      break;
    case TARGET_COUNTRIES_CODES.BRA:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_BRAZIL;
      break;
    case TARGET_COUNTRIES_CODES.DEU:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_GERMANY;
      break;
    case TARGET_COUNTRIES_CODES.ITA:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_ITALY;
      break;
    case TARGET_COUNTRIES_CODES.BGR:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_BULGARIA;
      break;
    case TARGET_COUNTRIES_CODES.UKR:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_UKRAINE;
      break;
    case TARGET_COUNTRIES_CODES.ZAF:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_SOUTH_AFRICA;
      break;
    case TARGET_COUNTRIES_CODES.GBR:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_ENGLAND;
      break;
    default:
      countryZoom = MapsConstants.ZOOM_IN_LIMIT_POLAND;
  }
  return countryZoom;
};

export const getZoomLevelForLocation = (place: string[]) => {
  if (place.includes('country')) {
    return 7;
  } else if (place.includes('region') || place.includes('place')) {
    return 10;
  }
  return 15;
};
