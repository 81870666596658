import { useTranslation } from 'react-i18next';

import {
  SeedDensityAdviceSectionContainer as SectionContainer,
  FullScreenChartContainer,
  Text,
} from 'pages/RecommendationV2/V2Common.styles';

import DensityDemandTable from './DensityDemandTable';

import {
  Field,
  Product,
  RankedSeed,
  recommendationResults,
} from 'base/types/RecommendationCropwise';
import { Title, SectionContent } from './SeedDensityAdvice.styles';
import { useEffect, useState } from 'react';
import { ProductSeed } from 'base/types/Products';
import { useFlowActions } from 'context/actions/flowActions';
import { useBreakpoint } from 'hooks';
import { useAppState } from 'context/AppState';
import { getBagsCount } from 'utils/getBagsCount';
import { getRecommendedSeeds } from 'utils/getRecommendedSeeds';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import DensityDemandTableMobile from './DensityDemandTableMobile/DensityDemandTableMobile';
import { CropConstants } from 'utils/constants/Crop';
import { getDataForSouthAfrica } from 'utils/getSeedRateAndBagSizeForSouthAfrica';
import { getDefaultSku } from 'utils/convertSeedsToBagsForProduct';
import generateSeedDensityAdviceTableData from 'utils/getDataForSeedDensityAdviceTable';

interface SeedDensityAdviceProps {
  recommendationCropwise?: any;
  propertiesFieldsInfo?: Field[];
  productsInfo?: Product[];
  productsCatalogInfo?: ProductSeed[];
  sectionRef?: any;
  recommendationAssignments?: any;
  landscapeAllowed?: boolean;
  countryCode?: string;
  cropId?: string | number;
  isNonDDG: boolean;
}

export type SeedDensityField = Field &
  recommendationResults & {
    expanded: boolean;
    seedDemand: RankedSeed[];
    estimatedSize?: number;
    fieldSvg?: string;
    fieldId?: string;
  };

const SeedDensityAdvice = ({
  recommendationCropwise,
  propertiesFieldsInfo, // properties fields from cropwise recommendation
  productsInfo, // products from cropwise recommendation
  productsCatalogInfo, // products from product catalog
  sectionRef,
  landscapeAllowed,
  countryCode,
  cropId,
  isNonDDG,
  recommendationAssignments,
}: SeedDensityAdviceProps) => {
  const { t } = useTranslation();
  const { isMobile, landscape } = useBreakpoint();
  const [fieldsData, setFieldsData] = useState<SeedDensityField[]>([]);
  const { setSeedDensityAdviceData } = useFlowActions();
  const { setRecommendationSeedsInfo } = useRecommendationFormActions();
  const [updatedDataSource, setUpdatedDataSource] = useState([]);
  const {
    apiData: { productCatalog, countryList },
  } = useAppState();

  const calculateBagSizeOtherThanSA = (product: ProductSeed | undefined) => {
    const defaultBagSize = 0;
    if (product?.sku?.length) {
      const sku = product?.sku?.filter((item) => item.bagSize !== null) || [];
      return getDefaultSku(product) || sku[0]?.bagSize || defaultBagSize;
    }
    return defaultBagSize;
  };

  const formattedSeedRate = (seedRate: number): number => {
    return cropId === CropConstants.BARLEY_CROP_ID ? seedRate / 10000 : seedRate;
  };

  const calculateDataForSouthAfrica = (
    countryCode: string | undefined,
    seedRate: number,
    product: ProductSeed | undefined,
    isSeedRate: boolean
  ) => {
    if (countryCode === CropConstants.SOUTH_AFRICA_CODE) {
      return getDataForSouthAfrica(
        countryCode,
        recommendationCropwise?.agronomicInputs?.spatialClass,
        product?.commercialName ?? '',
        countryList,
        isSeedRate
      );
    }
    return isSeedRate ? formattedSeedRate(seedRate) : calculateBagSizeOtherThanSA(product);
  };

  const checkIfCalculateSeedRateisNotNull = (recommendation: recommendationResults) => {
    return !recommendation?.CalculatedSeedRate.every((item: any) => item === null);
  };
  const findFieldDataFromAssignment = (recommendationAssignments: any[], fieldId: string) => {
    return recommendationAssignments.find((item: any) => item.field_id === fieldId);
  };

  const findProductToConsiderFromAssignment = (
    fieldDataFromAssignment: any,
    product: ProductSeed | undefined
  ) => {
    return fieldDataFromAssignment?.products?.find((item: any) => item.product_id === product?.id);
  };

  const findMatchingProduct = (calculatedSeedRate: any[], productName: string) => {
    return calculatedSeedRate.find((item: any) => Object.keys(item)[0] === productName);
  };

  const combineFieldsInfoAndCropwiseRecommendation = (
    recommendationCropwiseResult: recommendationResults[],
    propertiesFieldsInfo: Field[] | undefined
  ) => {
    let isExpanded = false;
    let firstTimeExpanded = true;
    const fieldsInfo: SeedDensityField[] = recommendationCropwiseResult
      ?.map((recommendation: recommendationResults) => {
        const field = propertiesFieldsInfo?.find((item) => recommendation.FieldId === item.id);
        if (propertiesFieldsInfo && propertiesFieldsInfo.length > 3) {
          if (firstTimeExpanded) {
            isExpanded = true;
            firstTimeExpanded = false;
          } else {
            isExpanded = false;
          }
        } else if (propertiesFieldsInfo && propertiesFieldsInfo?.length <= 3) {
          isExpanded = true;
        }
        const seedDemand = getRecommendedSeeds(recommendation)?.map((seed: any, idx) => {
          const product = productsCatalogInfo?.find((p) => {
            return 'SeedName' in seed
              ? p.productName === seed.SeedName
              : p.productName === seed?.Seed?.SeedName;
          });
          const hectares = (field?.estimatedSize as number) || 0;
          const fieldDataFromAssignment = findFieldDataFromAssignment(
            recommendationAssignments,
            field?.id?.toString() ?? ''
          );
          const productToConsiderFromAssignment =findProductToConsiderFromAssignment(fieldDataFromAssignment, product);
          let defaultSeedingRate;
          if (productToConsiderFromAssignment) {
            defaultSeedingRate =
              productToConsiderFromAssignment?.seed_rate ||
              Number(product?.defaultSeedingRate) ||
              0;
          } else if (isNonDDG) {
            let matchingProduct;
            if (recommendation?.CalculatedSeedRate) {
              matchingProduct = checkIfCalculateSeedRateisNotNull(recommendation)
                ? findMatchingProduct(
                  recommendation.CalculatedSeedRate,
                  product?.productName.toString() ?? ''
                )
                : 0;
            }
            defaultSeedingRate = matchingProduct ? Object.values(matchingProduct)[0] : null;
            if (recommendationCropwise?.agronomicInputs?.cropId === CropConstants.BARLEY_CROP_ID) {
              defaultSeedingRate = Number(product?.defaultSeedingRate) || 0;
            }
          }
          const spatialClass = recommendationCropwise?.agronomicInputs?.spatialClass ?? '';
          // Do we need to calculate number of bags on FE ???
          // getBagsCount has logic for SA cant remove directly
          const numberOfBags = getBagsCount({
            product,
            hectares,
            defaultSeedingRate,
            countryList,
            countryCode,
            cropId,
            spatialClass,
          });
          return {
            ...seed,
            rank: idx + 1,
            variety: product?.commercialName ?? '',
            plants: Number(defaultSeedingRate / 10000),
            seeds: calculateDataForSouthAfrica(countryCode, defaultSeedingRate, product, true),
            plantingArea: hectares,
            numberOfBags,
            bagSize: calculateDataForSouthAfrica(countryCode, 0, product, false),
          };
        });
        if (field) {
          return {
            ...field,
            ...recommendation,
            name: field.name.split('-')[0],
            expanded: isExpanded, // for table purposes
            seedDemand: [...seedDemand],
          } as SeedDensityField;
        }
      })
      .filter(Boolean) as SeedDensityField[];
    return fieldsInfo;
  };
  const fieldsInfo = combineFieldsInfoAndCropwiseRecommendation(
    recommendationCropwise?.recommendation ?? [],
    propertiesFieldsInfo
  );

  const getProductNames = (fields: SeedDensityField[]) => {
    const fieldsInfoResult = fields.map((field) => {
      const product = productsInfo?.find(
        (productItem) => productItem.product_name === field.SelectedSeed?.SeedName
      );
      if (product) {
        return {
          ...field,
          commercial_name: product.commercial_name,
        };
      } else {
        return field;
      }
    });
    return fieldsInfoResult;
  };
  useEffect(() => {
    if (
      !recommendationCropwise ||
      !propertiesFieldsInfo ||
      !productsCatalogInfo ||
      !recommendationCropwise?.recommendation.length ||
      !propertiesFieldsInfo.length ||
      !productsCatalogInfo.length ||
      !countryList.length
    ) {
      return;
    }
    const fieldsInfoProduct = getProductNames(fieldsInfo);
    const fieldsInfoProductData =
      countryCode?.trim() === CropConstants.BULGARIA_CODE
        ? fieldsInfoProduct.map((item) => ({
          ...item,
          seedDemand: item.seedDemand.map((data) => ({
            ...data,
            seeds: Number(data.seeds) / 10,
          })),
        }))
        : fieldsInfoProduct;
    setRecommendationSeedsInfo({ recommendationSeedsInfo: fieldsInfoProduct }); // to be used for voucherURL
    setSeedDensityAdviceData({ data: fieldsInfoProductData });
    setFieldsData(fieldsInfoProductData);
  }, [
    propertiesFieldsInfo,
    recommendationCropwise,
    productsInfo,
    productsCatalogInfo,
    setSeedDensityAdviceData,
    countryList,
    countryCode,
    cropId,
  ]);
  useEffect(() => {
    let source = [];
    if (fieldsData.length) {
      if (isNonDDG) {
        source = generateSeedDensityAdviceTableData(
          recommendationAssignments,
          fieldsData,
          productCatalog
        );
        if (fieldsData.length > 1) {
          // Remove all rankings and alternative products if there are 2 or more fields
          source = source.map((field: any) => ({
            ...field,
            seedDemand: field.seedDemand.slice(0, 1),
          }));
        } else {
          // Keep 3 products and their rankings if there is only 1 field
          source = source.map((field: any) => ({
            ...field,
            seedDemand: field.seedDemand.slice(0, 3),
          }));
        }
      } else {
        source = fieldsData.map((field: any, index: number) => ({
          ...field,
          expanded: index === 0,
        }));
      }
      setUpdatedDataSource(source);
    }
  }, [fieldsData, isNonDDG, recommendationAssignments, productCatalog]);
  return (
    <div ref={sectionRef}>
      <FullScreenChartContainer fullScreen={landscapeAllowed}>
        <SectionContainer>
          <SectionContent>
            {((isMobile && !landscape) || !isMobile) && (
              <>
                <Title>{t('Seed Density Demand and Advice')}</Title>
                <Text>
                  {t(
                    'Based on our Density Advice for each product we calculated the Seed Demand for your fields'
                  )}
                </Text>
              </>
            )}
            {isMobile ? (
              <>
                {landscape && (
                  <DensityDemandTable
                    dataSource={fieldsData}
                    isNonDDG={isNonDDG}
                    countryCode={countryCode?.trim()}
                    updatedDataSource={updatedDataSource}
                  />
                )}
              </>
            ) : (
              <DensityDemandTable
                dataSource={fieldsData}
                countryCode={countryCode?.trim()}
                isNonDDG={isNonDDG}
                updatedDataSource={updatedDataSource}
              />
            )}
          </SectionContent>
          {isMobile && (
            <DensityDemandTableMobile
              dataSource={fieldsData}
              isNonDDG={isNonDDG}
              countryCode={countryCode?.trim() ?? ''}
              updatedDataSource={updatedDataSource}
            />
          )}
        </SectionContainer>
      </FullScreenChartContainer>
    </div>
  );
};

export default SeedDensityAdvice;
