import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  StyledControlInput,
  StyledText,
  ProgressIcon,
  VaReportInputSection,
  StyledUpperTextError,
  VaProgressMain,
  VaProgressTextSection,
  ProgressTitleText,
  ProgressMainText,
} from './VaBanner.styles';
import { RefObject, useState } from 'react';
import progress from 'assets/images/timer_logo.svg';
import caution from 'assets/images/red_caution.svg';
import progressMobile from 'assets/images/vaProgress.svg';
import upperProgress from 'assets/images/va_upper_two.svg';
import './VaBanner.css';

interface VaEmailInputProps {
  upperBanner: boolean;
  error: boolean;
  vaEmailInputRef: RefObject<any>;
  isDDG: boolean | undefined;
  isMobile?: boolean;
}

interface HeaderProps {
  reportSubmited: boolean;
}

interface ProgressProp {
  upperBanner: boolean;
  isMobile?: boolean;
}
export const VaEmailInput = (props: VaEmailInputProps) => {
  const { error, vaEmailInputRef, upperBanner, isDDG, isMobile = false } = props;
  const [mail, setMail] = useState<string>('');
  const [t] = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
  };
  return (
    <VaReportInputSection upperBanner={upperBanner} isMobile={isMobile}>
      {(!isMobile || (isMobile && error)) && (
        <StyledUpperTextError visible={error}>
          <ExclamationCircleOutlined />
          <StyledText error={error} top={true} upperBanner={true} isDDG={false} isMobile={isMobile}>
            {t('Insert an email to receive the assessment.')}
          </StyledText>
        </StyledUpperTextError>
      )}
      <StyledControlInput
        type={'text'}
        ref={vaEmailInputRef}
        inputMode={'email'}
        id={`va-email-input-box`}
        data-testid="va-email-input"
        status={error ? 'error' : ''}
        name={'Email'}
        value={mail}
        placeholder={'example@email.com'}
        onChange={handleChange}
        allowClear={false}
        $hasErrors={error}
        suffix={error ? <img src={caution} alt="caution" /> : <span />}
      />
      {!isDDG && !upperBanner && (
        <StyledText error={error} top={false} upperBanner={false} isDDG={isDDG} isMobile={isMobile}>
          {t('Assessment will be available within 24 hours.')}
        </StyledText>
      )}
    </VaReportInputSection>
  );
};

export const VaBannerHeader = (prop: HeaderProps) => {
  const { reportSubmited } = prop;
  const [t] = useTranslation();
  return (
    <div className="va-header">
      <div className="va-title">
        {!reportSubmited && <span className="light-text">{t('Get your Free')} </span>}
        <span className="bold-text">{t('Field Variability Assessment & Autozone Report')}</span>
      </div>
      {!reportSubmited && (
        <div className="va-subtitle">
          {t(
            'Discover the best planting strategy for maximum yield with our comprehensive Field Assessment'
          )}
        </div>
      )}
    </div>
  );
};

export const VaInprogress = (prop: ProgressProp) => {
  const { upperBanner, isMobile } = prop;
  const { t } = useTranslation();

  const getImage = () => {
    if (isMobile && upperBanner) {
      return progressMobile;
    }
    return upperBanner ? upperProgress : progress;
  };

  return (
    <VaProgressMain upperBanner={upperBanner} isMobile={isMobile}>
      <ProgressIcon upperBanner={upperBanner} isMobile={isMobile}>
        <img src={getImage()} alt={'progress-icon'} />
      </ProgressIcon>
      <VaProgressTextSection upperBanner={upperBanner} isMobile={isMobile}>
        <ProgressTitleText upperBanner={upperBanner}>
          {t('Variability Assessment in Progress...')}
        </ProgressTitleText>
        <ProgressMainText upperBanner={upperBanner}>
          {t(
            'To help you determine the best planting approach for your farm, our experts are conducting a variability assessment of your fields.  You will be notified within 24 hours as soon as the results are ready.'
          )}
        </ProgressMainText>
      </VaProgressTextSection>
    </VaProgressMain>
  );
};
