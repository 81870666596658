import styled from 'styled-components';
import { Input, SYT_PREFIX, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { InputProps } from 'antd';

interface ControlInputProps extends InputProps {
  $hasErrors: boolean;
}

interface ErrorProps {
  error: boolean;
  top: boolean;
  upperBanner: boolean;
  isDDG: boolean | undefined;
  isMobile?: boolean;
}

interface UpperErrorProps {
  visible: boolean;
}

interface ButtonProps {
  isDDG: boolean | undefined;
  isMobile?: boolean;
  lowerBanner?: boolean;
}

interface ProgressMainProp {
  upperBanner: boolean;
  isMobile?: boolean;
}

export const StyledControlInput = styled(Input)<ControlInputProps>`
  height: 40px;
  line-height: normal;
  width: 100%;

  &.syt-antd-input {
    height: 80px !important;
  }

  &.${SYT_PREFIX}-input-affix-wrapper {
    height: 40px !important;
    background-color: #ffffff !important;
    border: ${(props: ControlInputProps) =>
    (props.$hasErrors ? `1px solid ${colors.red50} !important` : `1px solid ${colors.neutral30}`)};
    box-shadow: ${(props: ControlInputProps) =>
    (props.$hasErrors ? `0px 0px 0px 0.1px ${colors.red50} !important` : '')};

    & input {
      background-color: #ffffff !important;
    }
  }

  &.${SYT_PREFIX}-input-affix-wrapper-focused {
    border: ${(props) =>
    (props.$hasErrors ? `1px solid ${colors.red50} !important` : `1px solid ${colors.blue50}`)};
    box-shadow: 0px 0px 0px 0.1px ${colors.red50} !important;
  }
`;

export const StyledText = styled.div<ErrorProps>`
    color: ${(props: ErrorProps) => (props.error ? '#CF3537' : '#868CA2')};
    font-size: 12px;
    line height: 20px;
    justify-content: ${(props: ErrorProps) =>
    (props.upperBanner || !props.isDDG ? 'left' : 'center')};
    display: flex;
    width: ${(props: ErrorProps) => {
    if (props.isMobile) {
      return '100%';
    }
    return props.isDDG ? '100%' : '74%';
  }};
    margin-top:${(props: ErrorProps) => (props.top ? '0px' : '3px')};
    margin-left:${(props: ErrorProps) => (props.top ? '10px' : '0')};
`;

export const StyledUpperTextError = styled.div<UpperErrorProps>`
    color: #CF3537;
    font-size: 12px;
    line height: 20px;
    display: flex;
    visibility: ${(props: UpperErrorProps) => (props.visible ? 'visible' : 'hidden')};

    .anticon svg {
     width: 16px !important;
     height: 18px !important;
    }
`;

export const Button = styled.button<ButtonProps>`
  padding: 10px 20px;
  background-color: #14803c;
  color: #ffffff;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: ${(props: ButtonProps) => (props.isMobile || props.isDDG ? '0px' : '10px')};
  height: fit-content;
  margin-top: ${(props: ButtonProps) => (props.lowerBanner ? '4px' : '22px')};
  min-width: ${(props: ButtonProps) => {
    if (props.isMobile) {
      return '100%';
    }
    if (props.isDDG) {
      return '275px';
    }
    return '210px';
  }};
`;

export const VaProgressMain = styled.div<ProgressMainProp>`
  padding: ${(props: ProgressMainProp) => {
    if (props.isMobile && props.upperBanner) {
      return '28px 10px';
    }
    if (props.isMobile) {
      return '13px 10px';
    }
    return props.upperBanner ? '28px 16px' : '12px 16px';
  }};
  width: ${(props: ProgressMainProp) => {
    if (props.isMobile) {
      return '100%';
    }
    return props.upperBanner ? '100%' : '45%';
  }};
  display: flex;
  background-color: #fff3dd;
  border: 1px solid #f0c355;
  border-radius: 4px;
  margin: ${(props: ProgressMainProp) => (props.upperBanner ? '-10px 0 0 0' : '0 auto')};
`;

export const VaProgressTextSection = styled.div<ProgressMainProp>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${(props: ProgressMainProp) => {
    if (props.isMobile) {
      return '10px';
    }
    return props.upperBanner ? '28px' : '16px';
  }};
  width: ${(props: ProgressMainProp) => {
    if (props.isMobile) {
      return '100%';
    }
    return props.upperBanner ? '70%' : '100%';
  }};
`;

export const ProgressTitleText = styled.div<ProgressMainProp>`
  display: flex;
  font-family: 'Noto Sans';
  font-weight: ${(props: ProgressMainProp) => (props.upperBanner ? '600' : '400')};
  font-size: ${(props: ProgressMainProp) => (props.upperBanner ? '20px' : '16px')};
  line-height: ${(props: ProgressMainProp) => (props.upperBanner ? '28px' : '24px')};
  color: #14151c;
  margin-bottom: ${(props: ProgressMainProp) => (props.upperBanner ? '7px' : '0px')};
`;

export const ProgressMainText = styled.div<ProgressMainProp>`
  font-family: 'Noto Sans';
  display: flex;
  font-weight: 400;
  text-align: left;
  font-size: ${(props: ProgressMainProp) => (props.upperBanner ? '16px' : '14px')};
  line-height: ${(props: ProgressMainProp) => (props.upperBanner ? '24px' : '20px')};
  color: #696f88;
`;

export const ProgressIcon = styled.div<ProgressMainProp>`
  margin-left: ${(props: ProgressMainProp) => {
    if (props.isMobile) {
      return '0px';
    }
    return props.upperBanner ? '47px' : '0';
  }};
`;

export const VaReportInputSection = styled.div<ProgressMainProp>`
  width: ${(props: ProgressMainProp) => {
    if (props.isMobile) {
      return '100%';
    }
    return props.upperBanner ? '66%' : '34%';
  }};
`;

export const VaLowerBannerMainContainer = styled.div`
  width: 92%;
  background-color: #f7f7f7;
  border-radius: 16px;
  border: 2px solid #a3a9b9;
  margin: 0px auto 20px auto;
`;

export const VaLowerBannerSecondContainer = styled.div`
  padding: 15px 13px 0px 13px;
  .carousel .slide img {
    width: 240px;
  };
`;

export const VaLowerBannerSubContainer = styled.div`
  padding: 10px 7px 0 7px;
  text-align: center;
`;

export const VaSubmitMainContainerMobile = styled.div`
  width: 94%;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0 auto 10px auto;
  padding: 14px 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

export const CarouselBlurOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: -64px;
  height: 65px;
  background: linear-gradient(to bottom, rgba(247, 247, 247, 0), rgba(247, 247, 247, 1));
  pointer-events: none;
`;
