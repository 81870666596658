import { CropConstants } from './constants/Crop';

export const numberToEuropeanFormat = (number: number | undefined, column?: string) => {
  return Number(number).toLocaleString('es-ES', { maximumFractionDigits: 2 });
};

export const numberFormat = (number: number | undefined, countryCode?: string) => {
  if (countryCode === CropConstants.BULGARIA_CODE) {
    return number;
  }
  const formattedNumber = Number(number).toLocaleString('es-ES', {
    maximumFractionDigits: 2,
    useGrouping: true,
  });
  return formattedNumber.replace(/\./g, ' ');
};

export const convertToDecimal = (number: number | string) => {
  const numStr = number.toString().replace('.', '');
  return parseFloat(numStr.slice(0, 4) + '.' + numStr.slice(4));
};
