import styled from 'styled-components';
import {
  SectionContainer,
  FullScreenChartContainer,
  Tabs,
} from '../../RecommendationV2/V2Common.styles';
import trialedProduct from 'assets/images/map-pins.png';
import userLocation from 'assets/images/user-location.png';
import { Spinner } from 'components/Spinner';
import { colors, Button, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

interface IsMobileProps {
  isMobile: boolean;
}

export const SectionContainerStyled = styled(SectionContainer)`
  flex: 1;
  .syt-antd-notification-bottom {
    position: absolute;
  }
  .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
    float: inherit;
    display: inline;
  }

  .mapboxgl-popup {
    z-index: 10;

    &-content {
      border-radius: 8px;
      background-color: ${colors.neutral90};
      color: ${colors.neutral20};
      padding: 16px 24px;
      width: 376px;
      display: flex;
      flex-direction: column;

      & div {
        display: flex;
        margin-bottom: 8px;
      }
      span {
        flex: 1;
        font-family: 'Noto Sans';
        font-weight: 400;
        font-size: 16px;
      }

      .popup-value {
        flex: none;
        font-size: 20px;
      }
    }

    &-close-button {
      font-size: 32px;
      right: 15px;
      top: 15px;
      color: ${colors.neutral60};
    }

    &-anchor-bottom .mapboxgl-popup-tip {
      border-top-color: ${colors.neutral90};
    }

    &-anchor-right .mapboxgl-popup-tip {
      border-left-color: ${colors.neutral90};
    }

    &-anchor-top-right .mapboxgl-popup-tip {
      border-bottom-color: ${colors.neutral90};
    }
    &-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: ${colors.neutral90};
    }

    &-anchor-top-left .mapboxgl-popup-tip {
      border-bottom-color: ${colors.neutral90};
    }

    &-anchor-left .mapboxgl-popup-tip {
      border-right-color: ${colors.neutral90};
    }

    &-anchor-bottom-left .mapboxgl-popup-tip {
      border-top-color: ${colors.neutral90};
    }

    &-anchor-bottom-right .mapboxgl-popup-tip {
      border-top-color: ${colors.neutral90};
    }

    &-anchor-top-left .mapboxgl-popup-content {
      border-top-left-radius: 0;
    }

    &-anchor-bottom-left .mapboxgl-popup-content {
      border-bottom-left-radius: 0;
    }

    &-anchor-bottom-right .mapboxgl-popup-content {
      border-bottom-right-radius: 0;
    }

    &-anchor-top-right .mapboxgl-popup-content {
      border-top-right-radius: 0;
    }
  }
  background-color: #f8fafe;
  border-color: #f8fafe;
  @media screen and ${breakpoints.maxWidth.sm} {
    background-color: #ffffff;
    border-color: #ffffff;
  }
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
`;

export const MapContainer = styled(FullScreenChartContainer)<IsMobileProps>`
  display: flex;
  flex: 1;
  min-height: 636px;
  width: 100%;
  @media screen and ${breakpoints.maxWidth.sm} {
    min-height: 636px;
  }
  padding: 0 !important;
  @media screen and (orientation: landscape) {
    min-height: ${({ isMobile }) => (isMobile ? '100%' : '636px')} !important;
  }
  .mapboxgl-map {
    overflow: hidden;
    height: 636px !important;
  }
  .mapboxgl-ctrl-logo {
    @media screen and ${breakpoints.maxWidth.sm} {
      position: ${({ fullScreen }) => (fullScreen ? 'fixed' : 'static')};
    }
  }
`;

export const StyledOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  background-color: ${colors.neutral100}75;
  position: absolute;
  z-index: 4;
`;

export const StyledButton = styled(Button)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: ${colors.neutral100};

  &:hover {
    color: ${colors.neutral100};
  }
`;

export const StyledDiv = styled.div`
  margin-right: 16px;
  &.hidden {
    display: none;
  }
`;
export const StyledFullscreenDiv = styled.div`
  margin-right: 10px;
  &.hidden {
    display: none;
  }
`;

export const StyledDivProducts = styled.div<IsMobileProps>`
  @media screen and (orientation: landscape) {
    margin-top: ${({ isMobile }) => (isMobile ? '-37px' : '')} !important;
  }

  &.hidden {
    display: none;
  }
  display: ${({ isMobile }) => (isMobile ? 'auto' : 'inline')};
`;

export const StyledCloseContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;

  &.hidden {
    display: none;
  }
`;

export const StyledUserMarker = styled.img.attrs((props) => ({
  src: props.src ?? userLocation,
}))`
  background-size: cover;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const StyledProductMarker = styled.img.attrs((props) => ({
  src: props.src ?? trialedProduct,
}))`
  background-size: cover;
  width: 29px;
  height: 34px;
  cursor: pointer;
`;

export const StyledNotificationContent = styled.div`
  font-weight: 600;
`;

interface TabsContainerProps {
  isMobilePortraitOrDesktop: boolean;
}

export const TabsContainer = styled.div<TabsContainerProps>`
  @media screen and ${breakpoints.maxWidth.xl} {
    margin-left: 16px;

    margin-top: 0px;
    margin-bottom: ${({ isMobilePortraitOrDesktop }) => (isMobilePortraitOrDesktop ? '0px' : '')};
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-top: 0px;
  }
`;

export const StyledTabs = styled(Tabs)`
  .${SYT_PREFIX}-tabs-tab-active .${SYT_PREFIX}-tabs-tab-btn {
    color: ${colors.blue60};
  }
  .${SYT_PREFIX}-tabs-ink-bar {
    background: ${colors.blue60};
  }
  .${SYT_PREFIX}-tabs-tab + .${SYT_PREFIX}-tabs-tab {
    @media screen and ${breakpoints.maxWidth.sm} {
      margin: 0 0 0 24px;
    }
  }
`;
