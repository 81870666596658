import { ProductSeed } from 'base/types/Products';
import { CropConstants } from './constants/Crop';

export const getDefaultSku = (item: any) => {
  const defaultSku = item.sku.find((sku: any) => sku.defaultSku && sku.available);
  return defaultSku || item.sku.find((sku: any) => sku.bagSize !== null) || [];
};

export const convertSeedsToBagsForProduct = (
  seedingRate: number,
  areaApplied: number,
  product: ProductSeed | undefined
): number => {
  if (product && product.sku.length > 0) {
    const sku = getDefaultSku(product) || product.sku[0];
    if (!sku?.bagSize) {
      return 0;
    };
    const bagSize = Number(sku.bagSize);
    const seedRateFromProductApi = product.crop.id === CropConstants.BARLEY_CROP_ID ? Number(product.defaultSeedingRate) : seedingRate;
    return bagSize === 0 ? bagSize : (Number(seedRateFromProductApi) * Number(areaApplied)) / bagSize;
  }
  return 0;
};
