import { useState, useLayoutEffect, useEffect, useMemo, useRef } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import {
  Map as MapReact,
  useMap,
  Source,
  Popup,
  Layer,
  MapRef,
  Marker as MarkerReact,
} from 'react-map-gl';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { CustomControl } from 'components/Maps/Controls/CustomControl';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { useAppState } from 'context/AppState';
import { useTranslation } from 'react-i18next';
import { SoilColors } from 'utils/constants/SoilColors';
import debounce from 'utils/debounce';
import { SelectedProductFieldInfo } from 'context/store/flowReducer';
import {
  MapContainer,
  MapSkeleton,
  SectionContainerStyled,
  FarmPin,
  ProductFieldInfoCard,
  SoilInfoCard,
} from './ProductSoilMap.styles';
import track from 'utils/amplitudeWrapper';
import { CenterMapControl } from 'components/Maps/Controls/CenterMapControl/CenterMapControl';
import centerWhiteMapIcon from '../../../assets/icons/centerWhiteMapIcon.svg';
import { MapViewLayers } from './Controls/MapView/MapViewLayers';
import ZoomControl from './Controls/ZoomControl/ZoomControl';
import {
  CarouselContainer,
  ProductTitleText,
  ProductsTitle,
  RecommendationProducts,
  StyledCarousel,
} from './RecommendationProductsCard/RecommendationProductsCard.styles';
import RecommendationProductsCard from './RecommendationProductsCard/RecommendationProductsCard';
import ProductFieldsInfoSection from './ProductFieldsInfoSection';
import FarmPopup from './FarmPopup';
import Seed from 'assets/icons/seed.svg';
import { center, centroid, feature, featureCollection } from '@turf/turf';
import { FieldGeometry } from 'base/types/RecommendationCropwise';
import { GeoJsonProperties } from 'geojson';
import FieldPopup from './FieldPopup';
import farmIcon from '../../../assets/images/Web.svg';
import MobileModal from './MobileModal/MobileModal';
import { useFlowActions } from 'context/actions/flowActions';
import FarmSelect from './FarmSelect';
import PropertySoilInfo from './MobileModal/PropertySoilInfo';
import { useBreakpoint } from 'hooks';
import { getTranslatedFieldName } from 'utils/constants/Fields';

interface SoilCharacteristicsProps {
  readonly isLoading?: boolean;
  readonly sectionRef?: any;
  readonly isMobile: boolean;
  readonly landscapeAllowed: boolean;
  readonly selectProduct?: string;
  readonly isDDG?: boolean;
  readonly mapCharRef?: any;
  readonly setSelectedProductAsEmpty: () => void;
}

interface GetSlideToShowT {
  width: number;
  size?: number;
}

export interface SoilPopData {
  name: string;
  size: number;
  color: string | undefined;
}
interface FieldFeature {
  type: string;
  geometry: FieldGeometry;
  properties: {
    productcolor?: string;
    productName: string;
    calculated_area: number;
    fieldName: string;
    farmId: string;
    soilType: string;
    soilColor: string;
    center: number[];
    soilTexture?: string;
    selectedProductOpacity: number;
    productcommercialName?: string;
    fieldId: string;
    originalFieldName: string;
  };
}

export interface PropertyData {
  farmId: string;
  coord: number[];
  farmName: string;
  farmSize: number;
  fieldCount: number;
}

export interface PopUpdata {
  productcolor?: string;
  calculated_area?: number;
  fieldName?: string;
  originalFieldName?: string;
  farmId?: string;
  soilType?: string;
  coords: number[];
  productcommercialName?: string;
  fieldId?: string;
  featureGeometry?: any;
  soilColor?: string;
  soilTexture?: string;
}

const GetSlideToShow = ({ width, size }: GetSlideToShowT): number => {
  let cardWidth = 378;
  const gap = 10;
  if (width <= 576) {
    cardWidth = 300;
  }
  const totalBoxWidth = cardWidth + gap;
  return width / totalBoxWidth;
};

export default function SoilCharacteristics(props: SoilCharacteristicsProps) {
  const { setSelectedProductFieldInfo, setShowLayerSelectionPopUp } = useFlowActions();
  const mapContainerRef = useRef(null);
  const {
    sectionRef,
    isMobile,
    landscapeAllowed,
    selectProduct,
    isDDG,
    mapCharRef,
    setSelectedProductAsEmpty,
  } = props;
  const { soilMap: map } = useMap();
  const {
    apiData,
    flow: { selectedProductFieldInfo, isFieldsView, showLayerSelectionPopUp },
  } = useAppState();

  const { recommendationCropwise } = apiData;
  const recommendedProducts = recommendationCropwise?.recommendations[0].products;
  const [t] = useTranslation();
  const screens = useBreakpoint();
  const { isDesktop } = screens;
  const [displayControls, setDisplayControls] = useState(false);
  const hasUserZoomed = useRef(false);
  const [slidesToShow, setSlidesToShow] = useState(GetSlideToShow({ width: window.innerWidth }));
  const [isLoading, setIsLoading] = useState(true);
  const mapInititalState = {
    zoom: 12,
    dragPan: isDesktop,
  };
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [mapFieldData, setMapFieldData] = useState<GeoJSON.FeatureCollection>({
    type: 'FeatureCollection',
    features: [],
  });
  const [farmData, setFarmData] = useState<PropertyData[]>([]);
  const [showFieldPopup, setShowFieldPopup] = useState(false);
  const [popUpData, setPopUpData] = useState<PopUpdata>({
    coords: [0.323243, 0.367887],
  });
  const [farmBound, setFarmBound] = useState<LngLatBounds>();
  const [showFarmPopup, setShowFarmPopup] = useState(false);
  const [farmPopUpData, setFarmPopUpData] = useState<PropertyData>();
  const [showFarmPin, setShowFarmPin] = useState(true);
  const [isDropAPin, setIsDropAPin] = useState(false);
  const [uniqueFieldSoils, setUniqueFieldSoils] = useState<string[]>([]);
  const [soilPopUpData, setSoilPopUpData] = useState<SoilPopData[]>([]);
  const [showMobileFieldPopUp, setShowMobileFieldPopUp] = useState(false);
  const [webFarmSelectClick, setWebFarmSelectClick] = useState(false);
  const [selectedFarmName, setSelectedFarmName] = useState('');
  const [showFarmSearchPopUp, setShowFarmSearchPopUp] = useState(false);

  useEffect(() => {
    const getAdditionalFieldDetail = (_id: string) => {
      if (recommendationCropwise) {
        const productIdAssignedTofield =
          recommendationCropwise.recommendations[0].multi_field.assignments.find(
            (item) => item.field_id === _id
          )?.products[0]?.product_id;

        const field =
          recommendationCropwise.recommendations[0].multi_field.config.recommendation.find(
            (item) => item.FieldId === _id
          );
        if (field) {
          const product = recommendationCropwise.recommendations[0].products.find((item) => {
            return isDDG
              ? item.product_name === field.SelectedSeed?.SeedName
              : item.product_id === productIdAssignedTofield;
          });
          return {
            color: product ? product.color : '#262863',
            name: product?.product_name ?? '',
            commercialName: product?.commercial_name,
            soilTexture: field.AdditionalData.YieldRankEurope.texture,
          };
        }
        return undefined;
      }
    };

    const getSoilColor = (soil: string) => {
      return SoilColors.find((item) => item.key === soil || item.label === soil);
    };

    if (recommendationCropwise) {
      const fields: FieldFeature[] = [];
      const farms: PropertyData[] = [];
      const bounds = new LngLatBounds();
      const uniqueSoil: string[] = [];
      const pinSolution = recommendationCropwise.solution_type !== 'multi_field';
      recommendationCropwise.recommendations[0].multi_field.geometry.properties.forEach(
        (property) => {
          let overAllSize = 0;
          property.fields.forEach((field) => {
            const { color, name, commercialName, soilTexture } = getAdditionalFieldDetail(
              field.id
            ) || {
              color: '',
              name: '',
              commercialName: '',
              soilTexture: '',
            };
            const soilInfo = getSoilColor(field.soilType);
            const fieldCenter = centroid(field.geometry);
            const fieldDisplayName = field.name?.split('-@@@');
            const fieldFeature = {
              type: 'Feature',
              geometry: field.geometry,
              properties: {
                productcolor: pinSolution ? colors.neutral00 : color,
                productName: name,
                calculated_area: field.calculated_area,
                fieldName: getTranslatedFieldName({ defaultName: fieldDisplayName[0], t }),
                originalFieldName: fieldDisplayName[0],
                farmId: field.property_id,
                fieldId: field.id,
                soilType: field.soilType,
                soilColor: soilInfo?.color ?? '#E7BC72',
                soilTexture: soilTexture,
                center: fieldCenter.geometry.coordinates,
                selectedProductOpacity: 0.6,
                productcommercialName: commercialName,
              },
            };
            overAllSize = overAllSize + field.calculated_area;
            if (!uniqueSoil.includes(field.soilType)) {
              uniqueSoil.push(field.soilType);
            }
            fields.push(fieldFeature);
            if (field.geometry.type.toLowerCase() === 'polygon') {
              field?.geometry.coordinates[0].forEach((coords: Array<number>) => {
                bounds.extend(new LngLat(coords[0], coords[1]));
              });
            } else {
              // if this is a multi-polygon
              field.geometry.coordinates.forEach((polygon: any) => {
                polygon.forEach((ring: number[][]) => {
                  ring.forEach((coords: number[]) => {
                    bounds.extend(new LngLat(coords[0], coords[1]));
                  });
                });
              });
            }
          });
          const featureBoundary = property.fields.map((item) => item.geometry);
          const collection = featureCollection(featureBoundary.map((field) => feature(field)));
          const centerPointFeature = center(collection);
          if (centerPointFeature?.geometry) {
            const centroidCoords = [
              centerPointFeature.geometry.coordinates[1],
              centerPointFeature.geometry.coordinates[0],
            ];
            farms.push({
              farmId: property.id,
              coord: centroidCoords,
              farmName: property.name,
              farmSize: overAllSize,
              fieldCount: property.fields.length,
            });
          }
        }
      );
      if (recommendationCropwise.recommendations[0].multi_field.geometry.properties.length > 1) {
        setShowFarmPin(true);
      } else {
        setShowFarmPin(false);
      }
      setUniqueFieldSoils(uniqueSoil);
      setIsDropAPin(pinSolution);
      setFarmData(farms);
      setFarmBound(bounds);
      setMapFieldData({
        type: 'FeatureCollection',
        features: fields as GeoJSON.Feature<GeoJSON.Geometry, GeoJsonProperties>[],
      });
      setIsLoading(false);
    }
  }, [recommendationCropwise, t]);

  const handleCardSelect = (selectedProd: string) => {
    if (isDropAPin) {
      return;
    }
    if (selectedProd === selectedProduct) {
      handleAllproduct();
    } else {
      setSelectedProduct(selectedProd);
      const featuresData = mapFieldData.features as FieldFeature[];
      const tempSelectedProductFields: SelectedProductFieldInfo[] = [];
      const updatedMapFieldData = featuresData.map((item) => {
        if (item.properties.productName !== selectedProd) {
          return {
            ...item,
            properties: {
              ...item.properties,
              selectedProductOpacity: 0,
            },
          };
        } else {
          tempSelectedProductFields.push({
            fieldName: item.properties.fieldName,
            originalFieldName: item.properties.originalFieldName,
            coords: item.geometry.coordinates as unknown as [number, number][],
            size: item.properties.calculated_area,
            color: item.properties.productcolor,
          });
          return {
            ...item,
            properties: {
              ...item.properties,
              selectedProductOpacity: 0.6,
            },
          };
        }
      });
      setSelectedProductFieldInfo({ data: tempSelectedProductFields });
      setMapFieldData({
        type: 'FeatureCollection',
        features: updatedMapFieldData as GeoJSON.Feature<GeoJSON.Geometry, GeoJsonProperties>[],
      });
      track('user interacts with 1st map and products', {
        'product clicked': selectedProd,
      });
    }
  };

  const trackZoomInteraction = () => {
    if (!hasUserZoomed.current) {
      hasUserZoomed.current = true;
      track('user interacts with 1st map and products', {
        'map zoom interaction': true,
      });
    }
  };

  const handleDrawerClose = () => {
    setShowLayerSelectionPopUp({ show: false });
    setShowMobileFieldPopUp(false);
    setShowFarmSearchPopUp(false);
    setWebFarmSelectClick(false);
  };

  const zoomIn = () => {
    map?.getMap().zoomIn();
    trackZoomInteraction();
  };

  const zoomOut = () => {
    map?.getMap().zoomOut();
    trackZoomInteraction();
  };

  const addControls = (map?: MapRef) => {
    map?.addControl(new CustomControl('MapViewLayers'), 'top-right');
    map?.addControl(new CustomControl('CenterMapControl'), 'top-right');
    map?.addControl(new CustomControl('zoomControl'), 'top-right');
  };

  const onLoadMap = () => {
    const mapLoaded = map;
    setDisplayControls(true);
    addControls(mapLoaded);
  };

  useLayoutEffect(() => {
    if (!recommendationCropwise) {
      return;
    }
    let boundObjet: any = {
      animate: false,
      padding: {
        top: 90,
        bottom: isMobile ? 200 : 190,
        left: 100,
        right: 100,
      },
    };

    if (farmData.length > 1) {
      boundObjet = { ...boundObjet, maxZoom: 9 };
    }
    if (farmBound) {
      map?.fitBounds(farmBound, boundObjet);
    }
  }, [map, recommendationCropwise, farmBound, isMobile, farmData]);

  useLayoutEffect(() => {
    const mapRef = map?.getMap();
    const resizer = new ResizeObserver(
      debounce(() => {
        // Recenter the map on any container size change
        mapRef?.resize();
      })
    );

    if (mapContainerRef.current) {
      resizer.observe(mapContainerRef.current);
    }

    return () => {
      resizer.disconnect();
    };
  }, [map]);

  useEffect(() => {
    if (uniqueFieldSoils.length) {
      const result: { name: string; size: number; color: string | undefined }[] = [];
      uniqueFieldSoils.forEach((item) => {
        let fieldOverAllSize = 0;
        const soilColor = SoilColors.find((_f) => _f.key === item || _f.label === item)?.color;
        const properties =
          recommendationCropwise?.recommendations[0].multi_field.geometry.properties;
        properties?.forEach((property) => {
          property.fields.forEach((field) => {
            if (field.soilType === item) {
              fieldOverAllSize = fieldOverAllSize + (field.declared_area || field.calculated_area);
            }
          });
        });
        result.push({
          name: item,
          size: fieldOverAllSize,
          color: soilColor,
        });
      });
      setSoilPopUpData(result);
    }
  }, [recommendationCropwise, uniqueFieldSoils]);

  const getFieldGeometry = (fieldId: string, farmId: string) => {
    if (recommendationCropwise) {
      const field = recommendationCropwise.recommendations[0].multi_field.geometry.properties
        .find((item) => item.id === farmId)
        ?.fields.find((_field) => _field.id === fieldId);
      return field?.geometry.coordinates as unknown as [number, number][];
    }
  };

  const handleClick = (e: mapboxgl.MapMouseEvent) => {
    e.originalEvent.stopPropagation();
    const mapLoaded = map;
    if (mapFieldData.features.length > 0 && mapLoaded && !isDropAPin) {
      const fieldFeature = mapLoaded?.queryRenderedFeatures(e.point, {
        layers: ['map-view-field-fill'],
      });
      if (fieldFeature.length > 0 && fieldFeature[0].properties) {
        const geometry = getFieldGeometry(
          fieldFeature[0]?.properties.fieldId,
          fieldFeature[0]?.properties.farmId
        );
        const coordinates = JSON.parse(fieldFeature[0]?.properties.center);
        setShowFieldPopup(false);
        setPopUpData({
          coords: coordinates,
          farmId: fieldFeature[0]?.properties.farmId,
          productcolor: fieldFeature[0]?.properties.productcolor,
          fieldId: fieldFeature[0]?.properties.fieldId,
          calculated_area: fieldFeature[0]?.properties.calculated_area.toFixed(2),
          productcommercialName: fieldFeature[0]?.properties.productcommercialName,
          fieldName: fieldFeature[0]?.properties.fieldName,
          originalFieldName: fieldFeature[0]?.properties.originalFieldName,
          soilType: fieldFeature[0]?.properties.soilType,
          soilColor: fieldFeature[0]?.properties.soilColor,
          soilTexture: fieldFeature[0]?.properties.soilTexture,
          featureGeometry: geometry,
        });
        track('user interacts with 1st map and products', {
          'field clicked on map': true,
        });
        if (isMobile) {
          setShowMobileFieldPopUp(true);
          return;
        }
        setShowFieldPopup(true);
      } else {
        setShowFieldPopup(false);
      }
    }
  };

  const handleAllproduct = () => {
    if (!isDropAPin) {
      setSelectedProduct('');
      setSelectedProductAsEmpty();
      setSelectedProductFieldInfo({ data: [] });
      const featuresData = mapFieldData.features as FieldFeature[];
      const updatedMapFieldData = featuresData.map((item) => {
        return {
          ...item,
          properties: {
            ...item.properties,
            selectedProductOpacity: 0.6,
          },
        };
      });
      setMapFieldData({
        type: 'FeatureCollection',
        features: updatedMapFieldData as GeoJSON.Feature<GeoJSON.Geometry, GeoJsonProperties>[],
      });
    }
  };

  const handleMouseEnter = (farmId: string) => {
    const farm = farmData.find((item) => item.farmId === farmId);
    if (farm) {
      setFarmPopUpData(farm);
      setShowFarmPopup(true);
    }
  };

  const handleMouseLeave = () => {
    setShowFarmPopup(false);
  };

  const handleFarmClick = (_id: string) => {
    if (recommendationCropwise && map) {
      setWebFarmSelectClick(false);
      setShowFarmPopup(false);
      setShowFarmPin(false);
      const lngLatbounds = new LngLatBounds();
      const farm = recommendationCropwise.recommendations[0].multi_field.geometry.properties.find(
        (item) => item.id === _id
      );
      if (farm) {
        setSelectedFarmName(farm.name);
        setShowFarmSearchPopUp(false);
        farm.fields.forEach((item) => {
          item.geometry.coordinates[0].forEach((coords: Array<number>) => {
            lngLatbounds.extend(new LngLat(coords[0], coords[1]));
          });
        });
        map?.fitBounds(lngLatbounds, {
          animate: false,
          padding: {
            top: 100,
            bottom: 200,
            left: 100,
            right: 100,
          },
        });
        track('user interacts with 1st map and products', {
          'farm changed': true,
        });
      }
    }
  };

  const renderFarmLayer = useMemo(() => {
    if (!isDropAPin) {
      return null;
    }
    return farmData.map((item) => (
      <MarkerReact
        key={`${item.farmName}`}
        longitude={item.coord[1]}
        latitude={item.coord[0]}
        onClick={() => handleFarmClick(item.farmId)}
        anchor="bottom"
      >
        <FarmPin
          id={`${item.farmId}`}
          onMouseEnter={() => handleMouseEnter(item.farmId)}
          onMouseLeave={handleMouseLeave}
        >
          <img src={farmIcon} alt="farm-icon" />
        </FarmPin>
      </MarkerReact>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmData, map]);

  const renderGridFieldLayers = useMemo(() => {
    return (
      <Source key={'map-view-field'} type="geojson" data={mapFieldData}>
        <Layer
          id={'map-view-field-fill'}
          type="fill"
          source={'map-view-field'}
          paint={{
            'fill-color': isFieldsView ? ['get', 'productcolor'] : ['get', 'soilColor'],
            'fill-opacity': isFieldsView ? ['get', 'selectedProductOpacity'] : 0.8,
          }}
        />
        <Layer
          id={'map-view-field-text'}
          type="symbol"
          source={'map-view-field'}
          layout={{
            'text-field': isDropAPin ? '' : ['format', ['get', 'fieldName'], { 'font-scale': 1 }],
            'text-font': ['Open Sans Regular'],
            'text-size': 12,
            'text-anchor': 'center',
            'text-max-width': 10,
            'text-allow-overlap': false,
          }}
          paint={{
            'text-color': '#FFFFFF',
          }}
        />
        <Layer
          type="line"
          source={'map-view-field'}
          layout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': colors.neutral00,
            'line-width': 2,
          }}
        />
      </Source>
    );
  }, [mapFieldData, isFieldsView]);

  const onCenterMapButtonClick = () => {
    if (map && farmBound) {
      let boundObjet: any = {
        animate: false,
        padding: {
          top: 90,
          bottom: isMobile ? 200 : 190,
          left: 100,
          right: 100,
        },
      };

      if (farmData.length > 1) {
        setSelectedFarmName('');
        setShowFarmPin(true);
        boundObjet = { ...boundObjet, maxZoom: 9 };
      }
      map?.fitBounds(farmBound, boundObjet);
    }
  };

  const centerPaddingMap: { [key: number]: number } = {
    430: 14,
    390: 10,
    414: 12,
    412: 12,
    375: 9.7,
    360: 7.8,
  };

  const findClosest = (target: number): number => {
    const keys = Object.keys(centerPaddingMap).map(Number);
    const closestKey = keys.reduce(
      (prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev),
      430
    );
    return centerPaddingMap[target] || centerPaddingMap[closestKey] || 12;
  };

  const settings = {
    infinite: isMobile || false,
    dots: true,
    draggable: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: isMobile || false,
    centerPadding: `${findClosest(window.innerWidth)}%`,
    Infinity: false,
  };

  useEffect(() => {
    if (selectProduct && selectProduct !== selectedProduct) {
      handleCardSelect(selectProduct);
    }
  }, [selectProduct]);

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(
        GetSlideToShow({
          width: window.innerWidth,
        })
      );
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setSlidesToShow]);
  return (
    <SectionContainerStyled
      ref={(node) => {
        mapCharRef.current = node;
        sectionRef.current = node;
      }}
      data-testid={'soil-characteristics-map'}
    >
      {isLoading ? (
        <MapSkeleton />
      ) : (
        <MapContainer
          id="field-soil-characteristics-map"
          data-testid="soil-characteristics-map"
          ref={mapContainerRef}
          isMobile={isMobile}
          fullScreen={landscapeAllowed}
        >
          <MapReact
            id="soilMap"
            preserveDrawingBuffer={true}
            mapStyle={MapsConstants.MAP_STYLE_URL}
            initialViewState={mapInititalState}
            scrollZoom={false}
            onLoad={onLoadMap}
            onClick={handleClick}
          >
            <div data-testid="mapContainer" style={{ display: displayControls ? 'block' : 'none' }}>
              <ZoomControl
                id="zoomControl"
                buttonPlusProps={{ 'aria-label': 'Zoom In', onClick: zoomIn }}
                buttonMinusProps={{ 'aria-label': 'Zoom Out', onClick: zoomOut }}
              />
              {!isMobile && !isFieldsView && (
                <SoilInfoCard id="soilCard" data-testid="soilCard">
                  <PropertySoilInfo data={soilPopUpData} />
                </SoilInfoCard>
              )}
              {!isMobile && selectedProductFieldInfo.length > 0 && (
                <ProductFieldInfoCard id="fieldCard">
                  <ProductFieldsInfoSection isMobile={false} />
                </ProductFieldInfoCard>
              )}
              {!isMobile && farmData.length > 1 && (
                <FarmSelect
                  webFarmSelectClick={webFarmSelectClick}
                  setWebFarmSelectClick={setWebFarmSelectClick}
                  farmData={farmData}
                  selectFarmName={selectedFarmName}
                  handleFarmClick={handleFarmClick}
                  isMobile={isMobile}
                />
              )}
              <CenterMapControl
                id="CenterMapControl"
                isDisabled={false}
                onCenterMapButtonClick={onCenterMapButtonClick}
                customStyle={{
                  backgroundColor: colors.neutral90,
                  borderRadius: '8px',
                }}
                customIcon={centerWhiteMapIcon}
                showTooltip={true}
              />
              <MapViewLayers id="MapViewLayers" isFieldsView={isFieldsView} isMobile={isMobile} />{' '}
              <ProductsTitle onClick={() => handleAllproduct()}>
                <img src={Seed} alt="seeds" />
                <ProductTitleText>{t('All Recommended Products')}</ProductTitleText>
              </ProductsTitle>
              <RecommendationProducts
                id="recommendationProductsCard"
                data-testid="recommendationProductsCard"
              >
                {recommendedProducts && (
                  <CarouselContainer width={`${window.innerWidth}px`}>
                    <StyledCarousel {...settings}>
                      {recommendedProducts.map((data, index) => (
                        <RecommendationProductsCard
                          index={index}
                          isMobile={isMobile}
                          size={recommendedProducts.length}
                          key={data.product_name}
                          handleOnClick={(productName) => handleCardSelect(productName)}
                          {...data}
                          isProductSelected={data.product_name === selectedProduct}
                          isDDG={isDDG}
                        />
                      ))}
                    </StyledCarousel>
                  </CarouselContainer>
                )}
              </RecommendationProducts>
            </div>
            {showFieldPopup && (
              <Popup
                latitude={popUpData.coords[1]}
                longitude={popUpData.coords[0]}
                closeButton={false}
                closeOnClick={false}
                maxWidth="320px"
              >
                <FieldPopup data={popUpData} />
              </Popup>
            )}
            {(showFarmPin || isDropAPin) && renderFarmLayer}
            {renderGridFieldLayers}
            {showFarmPopup && farmPopUpData && (
              <Popup
                latitude={farmPopUpData.coord[0]}
                longitude={farmPopUpData.coord[1]}
                closeButton={false}
                closeOnClick={false}
                maxWidth="320px"
                offset={[0, -40]}
              >
                <FarmPopup data={farmPopUpData} />
              </Popup>
            )}
            {isMobile && showLayerSelectionPopUp && (
              <MobileModal
                title={t('Map view')}
                soilPopUpData={soilPopUpData}
                onClickOutside={handleDrawerClose}
                isFieldInfo={false}
                showFarmSearchPopUp={showFarmSearchPopUp}
                farmData={farmData}
                handleFarmClick={handleFarmClick}
                selectFarmName={selectedFarmName}
              />
            )}
            {isMobile && showMobileFieldPopUp && (
              <MobileModal
                title={t('Map view')}
                fieldPopUpData={popUpData}
                onClickOutside={handleDrawerClose}
                isFieldInfo={true}
                showFarmSearchPopUp={showFarmSearchPopUp}
                farmData={farmData}
                handleFarmClick={handleFarmClick}
                selectFarmName={selectedFarmName}
              />
            )}
            {isMobile && showFarmSearchPopUp && (
              <MobileModal
                title={t('Farms')}
                onClickOutside={handleDrawerClose}
                isFieldInfo={false}
                showFarmSearchPopUp={showFarmSearchPopUp}
                farmData={farmData}
                handleFarmClick={handleFarmClick}
                selectFarmName={selectedFarmName}
              />
            )}
          </MapReact>
        </MapContainer>
      )}
    </SectionContainerStyled>
  );
}
