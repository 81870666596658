import { useTranslation } from 'react-i18next';
import { ProductAdviceTitle } from './V2Common.styles';
import ExpertSeedAdvice from './Sections/ExpertSeedAdvice';
import ProductComparison from './Sections/ProductComparison/ProductComparison';

interface ProductAdviceProps {
  isMobile: boolean;
  isLoadingBreakoutsAPI: boolean;
  isLoadingBreakoutsProductSummaryAPI: boolean;
  isLoadingAttributesAPI: boolean;
  ProductComparisonRef: any;
  ExpertSeedRef: any;
  onClickToSeeDetails?: () => void;
  handleProductSelection?: (productName: string) => void;
  isBrazil: boolean;
}

const ProductAdvice = ({
  isMobile,
  isLoadingBreakoutsAPI,
  isLoadingBreakoutsProductSummaryAPI,
  isLoadingAttributesAPI,
  ProductComparisonRef,
  ExpertSeedRef,
  onClickToSeeDetails,
  handleProductSelection,
  isBrazil,
}: ProductAdviceProps) => {
  const [t] = useTranslation();

  return (
    <>
      <ProductAdviceTitle data-testid="product-advice-section">
        {t('Product Advice')}
      </ProductAdviceTitle>
      <ExpertSeedAdvice
        isLoading={isLoadingBreakoutsAPI || isLoadingBreakoutsProductSummaryAPI}
        sectionRef={ExpertSeedRef}
        isMobile={isMobile}
        onClickToSeeDetails={onClickToSeeDetails}
        handleOnClick={handleProductSelection}
      />
      {!isLoadingAttributesAPI && !isBrazil && (
        <ProductComparison sectionRef={ProductComparisonRef} />
      )}
    </>
  );
};

export default ProductAdvice;
