import styled from 'styled-components';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { colors } from 'theme/colors';

export const StyledIconPlus = styled(PlusOutlined)`
  font-size: '18px';
  color: ${colors.neutral70};
`;

export const StyledIconMinus = styled(MinusOutlined)`
  font-size: '18px';
  color: ${colors.neutral70};
`;

export const StyledContainer = styled('div')`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  & > button {
    width: 40px;
  }
`;
