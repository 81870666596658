import { StressType } from 'context/store/flowReducer';
import { CropConstants } from './constants/Crop';
import { BrazilCountryConstant } from './constants/CountryData';
import { findCentroidOfAllFieldsInRec } from 'utils/getCentroidFields';

const endDateForGrowthStage = (agronomicInputs: any, countryCode: string, cropName: string) => {
  if (agronomicInputs?.harvestDate) {
    return new Date(agronomicInputs.harvestDate);
  } else {
    const plantingDate = agronomicInputs?.plantingDate;
    let harvestDayAndMonth = '10-31';
    let harvestYear = plantingDate ? plantingDate.split('-')[0] : new Date().getFullYear();
    if (countryCode === BrazilCountryConstant.id) {
      if (cropName === CropConstants.CORN_SMALLCASE) {
        harvestDayAndMonth = '09-30';
      } else if (
        [CropConstants.SUMMER_CORN_SMALLCASE, CropConstants.SOYBEANS.toLowerCase()].includes(
          cropName
        )
      ) {
        harvestDayAndMonth = '04-30';
        harvestYear = Number(harvestYear) + 1;
      }
    }
    return new Date(`${harvestYear}-${harvestDayAndMonth}`);
  }
};

const createAbioticStageReqData = (
  recommendationCropwise: any,
  plantingDate: string,
  countryCode: string,
  abioticStageStressType: StressType,
  selectedAbioticIntensityValue: string,
  selectedAbioticFrequencyValue: string
) => {
  const { recommendations } = recommendationCropwise;
  const {
    crop_type: cropType,
    crop_name: cropName,
    multi_field: {
      geometry: { properties },
    },
  } = recommendations[0];
  const fieldCenter = findCentroidOfAllFieldsInRec(properties[0].fields);

  const getPredictionStartDate = (date: string): string => {
    const [_, monthPart, dayPart] = date.split('-');
    const currentYear = new Date().getFullYear();
    const year =
      abioticStageStressType === StressType.Frequency ? currentYear : selectedAbioticIntensityValue;

    if (
      countryCode === BrazilCountryConstant.id &&
      abioticStageStressType === StressType.Frequency &&
      [CropConstants.SUMMER_CORN_SMALLCASE, CropConstants.SOYBEANS.toLowerCase()].includes(cropName)
    ) {
      // Pass the startDate as the first day of the current year for Frequency as response will be same for all years
      return `${year}-01-01T00:00:00Z`;
    }

    const startDate = new Date(`${year}-${monthPart}-${dayPart}`);
    const cutoffDate = new Date();
    // If the planting date is more than 3 years old, set the start date as 3 years ago
    cutoffDate.setFullYear(cutoffDate.getFullYear() - 3);

    const adjustedStartDate = startDate < cutoffDate ? cutoffDate : startDate;

    return `${adjustedStartDate.getFullYear()}-${String(adjustedStartDate.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(adjustedStartDate.getDate()).padStart(2, '0')}T00:00:00Z`; // 2025-10-31T00:00:00Z
  };

  const getPredictionEndDate = (): string => {
    const date = endDateForGrowthStage(
      recommendationCropwise?.recommendations[0].multi_field.config?.agronomicInputs,
      countryCode,
      cropName
    );
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month as JS months are 0-based
    const currentYear = new Date().getFullYear();

    let year =
      abioticStageStressType === StressType.Frequency ? currentYear : selectedAbioticIntensityValue;

    if (
      countryCode === BrazilCountryConstant.id &&
      [CropConstants.SUMMER_CORN_SMALLCASE, CropConstants.SOYBEANS.toLowerCase()].includes(cropName)
    ) {
      // Pass the endDate as last day of the current year for Frequency as response will be same for all years
      if (abioticStageStressType === StressType.Frequency) {
        return `${year}-12-31T00:00:00Z`; // 2025-12-31T00:00:00Z
      }

      year = Number(year) + 1;
      let endDate = new Date(`${year}-${month}-${day}`);
      const maxFutureDate = new Date();
      // If the end date is more than 4 days in the future, set the end date as +4 days from today's date
      maxFutureDate.setDate(maxFutureDate.getDate() + 4);

      if (endDate > maxFutureDate) {
        endDate = maxFutureDate;
      }

      return `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(
        endDate.getDate()
      ).padStart(2, '0')}T00:00:00Z`; // Example: 2025-10-31T00:00:00Z
    }

    return `${year}-${month}-${day}T00:00:00Z`; // Default case
  };

  const data = {
    request_version: 'v1.0',
    fields: [
      {
        models: [
          {
            name: 'abiotic_stress',
            version: 'v1.0',
            ...(abioticStageStressType === StressType.Frequency && {
              metadata: {
                historical_time_period: {
                  no_years: selectedAbioticFrequencyValue === 'last20' ? 20 : 10,
                },
              },
            }),
          },
        ],
        location: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: fieldCenter?.geometry.coordinates,
          },
          properties: {
            distance_to_grid: {
              value: 50,
              unit: 'km',
            },
          },
        },
        query: {
          filter:
            abioticStageStressType === StressType.Frequency
              ? 'aggregated_stress_risk'
              : 'daily_stress_severity',
          prediction_time_period: {
            start: getPredictionStartDate(plantingDate), //'2024-04-30T00:00:00Z',
            end: getPredictionEndDate(), //'2024-10-31T00:00:00Z'
          },
        },
        crop: cropType.toLowerCase().includes('corn') ? 'CORN' : cropType.toUpperCase(),
        original_crop: cropType.toUpperCase(),
      },
    ],
  };
  return data;
};

const getStartDate = (recommendationCropwise: any, countryCode: string, cropName: string) => {
  let plantingDayAndMonth = '04-01';
  if (countryCode === BrazilCountryConstant.id) {
    if (cropName === CropConstants.CORN_SMALLCASE) {
      plantingDayAndMonth = '02-01';
    } else if (
      cropName === CropConstants.SUMMER_CORN_SMALLCASE ||
      cropName === CropConstants.SOYBEANS.toLowerCase()
    ) {
      plantingDayAndMonth = '10-01';
    }
  }
  return new Date(
    recommendationCropwise?.recommendations[0]?.multi_field?.config?.agronomicInputs
      ?.plantingDate ?? `${new Date().getFullYear()}-${plantingDayAndMonth}`
  );
};

export { createAbioticStageReqData, endDateForGrowthStage, getStartDate };
