import styled from 'styled-components';
import { Button } from 'syngenta-digital-cropwise-react-ui-kit';
import { colors } from 'theme/colors';

export const StyledButton = styled(Button)`
  margin-top: 25px;
  margin-left: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${colors.neutral70};
  &:hover {
    background-color: ${colors.neutral70};
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  .syt-antd-btn {
    background: ${colors.neutral90};
  }
`;

export const FarmSelect = styled.div`
  display: flex;
  z-index: 2;
`;
